import React from 'react';
import sliderTourConfig from '../../components/slider/tourConfig';
import InnerTabTourConfig from '../../components/InnerTabTourConfig';

export default [
  ...sliderTourConfig,
  ...InnerTabTourConfig,
  {
    selector: '[data-tut="changePasswordSection"]',
    content: () => (
      <div>
        <p>در این قسمت میتوانید پسورد فعلی خورد را تغییر دهید</p>
      </div>
    ),
  },
  {
    selector: '[data-tut="loginLogoutSection"]',
    content: () => (
      <div>
        <p>تاریخچه ورود و خروج شما در این بخش قرار دارد</p>
      </div>
    ),
  },
];
