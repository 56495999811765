import { IonApp, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { cssTransition, ToastContainer } from 'react-toastify';

import './styles/global.scss';
import './styles/chart.scss';
import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './fonts/icons/font/flaticon.scss';
import './theme/variables.css';
import React, { useState } from 'react';
import { Route, useParams, Redirect } from 'react-router';
import './styles/DarkMode.scss';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import { Button } from '@mui/material';
import Menu from './components/Menu';
import NoInternet from './screens/NoInternet';
import Session from './screens/Session';
import { AppProvider } from './contexts/AppContext';
import Auth from './components/Auth';
import { LayoutState } from './utils/interfaces';
import Tabs from './components/Tabs';
import IntroSlides from './components/Slides';
import SatrexDialog from './components/SatrexDialog';
import downloadApp from './images/lottie/downloadApp.json';
import { satrexGreen } from './styles/colors';

const installOptions = {
  loop: true,
  autoplay: true,
  style: { width: '50%' },
  animationData: downloadApp,

};

const MasterLayout = () => {
  const { name } = useParams<{ name: string }>();

  // checkAuth()
  switch (name) {
    case 'login':
      return <Session routeName={name} />;
    case 'register':
      return <Session routeName={name} />;
    default:

      return (
        <>
          <Auth>
            <Menu />
            <Tabs />
          </Auth>
        </>
      );
  }
};

const swirl = cssTransition({
  enter: 'swirl-in-fwd',
  exit: 'swirl-out-bck',
});

const App: React.FC = (props) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  const [showAlert1, setShowAlert1] = useState<boolean>(true);
  const language = useSelector((state: LayoutState) => state.layout.language);
  const redirections = useSelector((state: LayoutState) => state.layout.redirectUrls);
  // const [isTourOpen, setIsTourOpen] = useState(false);
  // const [isShowingMore, setIsShowingMore] = useState(false);

  const isLtr = language === 'EN';
  return (
    <QueryClientProvider client={queryClient}>

      <AppProvider>
        <IonApp className={isLtr ? 'ltrApp' : 'rtlApp'}>

          <IonReactRouter>
            <IonSplitPane contentId="main">

              {
                redirections.map((item: any, index: any) => (
                  <Redirect
                    key={item.fromUrl}
                    from={item.fromUrl}
                    to={item.toUrl}
                  />
                ))
              }
              <Route path="/no-internet" exact>
                <NoInternet />
              </Route>
              <Route path="/" exact>
                <Redirect to="/home" />
              </Route>
              <Route path="/market/:id" exact>
                <MasterLayout />
              </Route>
              <Route path="/market-deal/:id" exact>
                <MasterLayout />
              </Route>
              <Route path="/wallet/:coinname" exact>
                <MasterLayout />
              </Route>
              <Route path="/intro" exact>
                <IntroSlides />
              </Route>
              <Route path="/:name" exact>
                  <MasterLayout />
              </Route>

            </IonSplitPane>
          </IonReactRouter>
        </IonApp>
        <SatrexDialog
          open={showAlert1}
          handleClose={() => setShowAlert1(false)}
          otherProps={{
            className: 'installPwaAlert',
          }}
          content={(
            <>
              <Lottie
                options={installOptions}
                height={250}

              />
              <h6 style={{ textAlign: 'center', lineHeight: 2 }}>
                مایلید ساتکرس را بر روی گوشی خود نصب کنید؟
              </h6>
              <div style={{
                width: '100%', marginTop: 10, display: 'flex', justifyContent: 'center',
              }}
              >
                <Button style={{ color: 'gray' }}>بعدا</Button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button style={{ backgroundColor: satrexGreen }} variant="contained" className="installPwa">نصب</Button>
              </div>

            </>
          )}
        />
        {/* <IonAlert */}
        {/*  isOpen={showAlert1} */}
        {/*  onDidDismiss={} */}
        {/*  cssClass="installPwaAlert" */}
        {/*  // header={'Alert'} */}
        {/*  subHeader="نصب ساتکرس" */}
        {/*  message={<div></div>} */}
        {/*  buttons={[ */}
        {/*    { */}
        {/*      text: 'بعدا', */}
        {/*    }, */}
        {/*    { */}
        {/*      text: 'بله', */}
        {/*      cssClass: 'installPwa', */}
        {/*    }, */}
        {/*  ]} */}
        {/* /> */}

        <ReactQueryDevtools initialIsOpen />

        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={!isLtr}
          pauseOnFocusLoss
          style={{ height: 50 }}
          draggable
          pauseOnHover
          transition={swirl}

        />
      </AppProvider>

    </QueryClientProvider>
  );
};

export default App;
