import React, { useState } from "react";

const dic = {
  dashboard: "Tableau de bord ",
  wallet: "Portefeuille ",
  markets: "Marchés",
  quickDealing: "Offre rapide",
  systemGuide: "Guide du système",
  today: "Aujourd'hui",
  BackToDashboard: "Retour au tableau de bord",
  profile: "Profil",
  security: "Sécurité ",
  accounting: "Comptabilité ",
  report: "Rapports",
  support: "Soutien",
  exit: "Sortir ",
  NegotiableCapital: "Capital négociable",
  TomanCapital: "capitale tomane",
  SatrexDayTradingVolume: "volume de trading journalier de Satrex",
  editPersonalInfo: "Modifier les informations personnelles",
  legal: "légal",
  natural: "Naturel ",
  user: "",
  level: "Niveau ",
  woman: "Femme ",
  man: "Homme ",
  firstName: "Prénom ",
  lastName: "Nom de famille ",
  nationalId: "Code national",
  birthDate: "Date de naissance ",
  email: "E-mail",
  postCode: "Code postal ",
  address: "Adresse",
  telephone: "téléphone",
  phoneNumber: "Mobile",
  walletStatus: "État du portefeuille",
  uploadNationalCardImage: "Télécharger une image de carte nationale",
  pictureOnNationalCard: "Photo sur carte nationale",
  sendPersonalPhotoAsPattern:
    "Envoyer une image personnelle selon l'échantillon",
  saveInfo: "Sauvegarder ",
  enable: "Permettre ",
  disable: "Désactiver ",
  seeSample: "Voir exemple",
  dragAndDropImageOrClick: "Glissez et déposez l'image ou cliquez sur",
  buy: "Acheter ",
  sell: "Vendre ",
  depositAndWithdrawCrypto: "Dépôt et retrait de crypto-monnaie",
  deposit: "Déposer de l'argent ",
  withdrawal: "Retrait ",
  turnover: "Chiffre d'affaires",
  addCard: "Ajouter une carte bancaire",
  addBankAccount: "Ajouter un compte bancaire",
  requests: "Demandes",
  editProfile: "Modifier les informations personnelles",
  editBankInfo: " Modifier les informations bancaires",
  messages: "Messages",
  personalInfo: "Informations personnelles",
  contactInfo: "Contacts",
  sendingDocs: "Envoyer des documents",
  tether: "Amarre",
  UserLevelUpgrade: "Mise à niveau du niveau utilisateur",
  YourUserlevel: "Votre niveau d'utilisateur",
  YourTransactionFee: "Vos frais de transaction",
  DailyWithdrawalCeiling: "Plafond de retrait journalier",
  DailyCryptoWithdrawalCeiling:
    "Plafond de retrait quotidien de la crypto-monnaie",
  rial: "Rial",
  NumberOfOpenOrders: "Nombre de commandes ouvertes",
  ValueOfTransactionsInTheLast30Days:
    "Valeur des transactions au cours des 30derniers jours",
  NumberOfOpenBuyOrders: "Nombre d'ordres d'achat en cours",
  NumberOfOpenSellOrders: "Nombre d'ordres de vente ouverts",
  YourTotalAssets: "Vos actifs totaux",
  WalletStatus: "État du portefeuille",
  MarketsInTheLast24Hours: "Marchés au cours des dernières 24 heures",
  searchCrypto: "Rechercher une crypto-monnaie",
  mostGrowth: "Le plus de croissance",
  mostFalls: "Le plus tombent",
  BasedOnMarketRanking: "Basé sur le classement du marché",
  BasedOnMarketCap: "Basé sur la capitalisation boursière",
  cryptocurrency: "Crypto-monnaie",
  price: "Prix ",
  changes: "Changements",
  totalSupply: "Approvisionnement en circulation",
  more: "Suite ",
  inventory: "Inventaire",
  AverageBuy: "Achat moyen",
  PriceInTomans: "Prix en toman",
  PriceInTether: "Prix en Tether",
  "24Change": "Changement en pourcentage au cours des dernières 24heures",
  market: "Marché ",
  systemExit: "Se déconnecter ",
  sureToGetOut: "Êtes-vous sûr de vouloir vous déconnecter? ",
  cancel: "Annuler",
  yes: "Oui",
  Deposit: "Verser ",
  chooseCrypto: "Choisissez une crypto-monnaie",
  WithdrawalAmount: "Montant de retrait ",
  destinationWalletAddress: "Adresse du portefeuille de destination",
  TwoFactorCode: "Code d'identification à deux facteurs",
  requestSMScode: "Demander un code SMS",
  transferFee: "Frais de transfert ",
  SubmitWithdrawalRequest: "Soumettre une demande de retrait",
  depositCryptocurrency: "Dépôt de crypto-monnaie",
  walletBalance: "Solde du portefeuille",
  bankCard: "Carte bancaire",
  depositAmount: "Montant du dépôt",
  yourDepositCeiling: "Votre plafond de caution",
  TransferBankPortal: "Transfert vers le portail bancaire",
  withdrawalTime: "Délai de retrait",
  confirm: "Confirmer",
  clear: "Dégager",
  withdrawalDateNote:
    "Noter; La date de retrait ne peut être qu'à partir de demain et un jour ouvrable",
  withdrawalAmount: "Montant de retrait",
  WithdrawalTotalInventory: "inventaire total",
  DestinationAccountNumber: "Numéro de compte de destination",
  BankingNetworkTransferFee: "Frais de transfert de réseau bancaire",
  id: "IDENTIFIANT",
  date: "Date",
  time: "Temps",
  type: "Taper",
  currency: "Monnaie",
  amount: "Montant",
  fee: "Frais",
  Withdrawal: "Retrait",
  transactionDetails: "Détails de la transaction",
  symbol: "Symbole",
  bankTrackingID: "Identifiant de suivi bancaire",
  fullName: "prénom et nom",
  requestDay: "jour de la demande",
  status: "Statut",
  description: "La description",
  bankCardNumber: "Numéro de carte bancaire",
  bankIbanNumber: "Numéro IBAN de la banque",
  bankName: "Nom de banque",
  awaitingApproval: "En attente d'approbation",
  accepted: "Accepté",
  rejected: "Rejeté",
  bank: "Banque",
  meliBank: "Méli Iran",
  sepahBank: "Sépah",
  toseBank: "Tose Sadérat",
  sanatBank: "Sanat va Madan",
  keshavarziBank: "Keshavarzi",
  maskanBank: "Maskan",
  postBank: "Post Bank Iran",
  toseTavonBank: "Tose Taavon",
  eghtesadBank: "Eghtesad Novin",
  parsianbank: "Parsian",
  pasargardBank: "Pasargade",
  karafarinBank: "Karafarine",
  samanbank: "Saman",
  sinaBank: "Sina",
  sarmayeBank: "Sarmaye",
  tatBank: "Tat",
  shahrBank: "Shahr",
  deyBank: "Dey",
  saderatBank: "Sadérat",
  melatBank: "Mélat",
  tejarat: "Téjarat",
  refahbank: "Refa",
  ansarBank: "Ansar",
  mehrEghtesadBank: "Mehr Eghtesad",
  zeroLevelUser: "Utilisateur de niveau zéro",
  oneLevelUser: "Utilisateur à un niveau",
  twoLevelUser: "Utilisateur à deux niveaux",
  authentication: "Authentification",
  bankInfo: "Informations bancaires",
  personalImage: "Photo personnelle",
  adminRejection:
    "Les informations saisies dans cette section n'ont pas été approuvées par l'administrateur! S'il vous plaît soumettre à nouveau après l'édition",
  adminAcceptation:
    "Les informations de cette section ont été approuvées par l'administrateur",
  adminPending:
    "Les informations de cette section ont été envoyées à l'administrateur et sont en attente d'approbation",
  cancelRequest: "demande d'annulation",
  province: "Province",
  city: "ville",
  submitInformation: "soumettre des informations",
  submitInfoAsk:
    "Cliquez sur 'Envoyer' si vous êtes sûr que vos informations sont correctes",
  save: "enregistrer",
  saveAndSubmit: "sauvegarder et soumettre",
  varifyEmailCode:
    "Le code de vérification a été envoyé à votre adresse e-mail",
  send: "envoyer",
  validationCode: "Code de validation",
  code: "code",
  topic: "sujet",
  sendDate: "Date envoyée",
  changePassword: "Changer le mot de passe",
  enterCurrentPassword: "Entrer votre mot de passe actuel",
  EnterNewPassword: "Entrez votre nouveau mot de passe",
  repeatNewPassword: "Répétez pour saisir votre mot de passe actuel",
  activeTwoFactorIdentification: "Activer l'identification en deux étapes",
  ActivateGoogleAuth: "Activer l'authentificateur google",
  EnableLoginSMS: "Activer le SMS de connexion",
  LoginLogoutInformation: "Informations de connexion et de déconnexion",
  browserId: "Identifiant du navigateur",
  Ip: "Ip",
  LoginLogout: "Connexion et déconnexion",
  ViewFinancialReports: "Afficher les rapports financiers",
  AmountInRials: "Montant en rials",
  networkFee: "Frais de réseau",
  maxBuy: "Montant maximum de votre achat",
  registerBuyorder: "Enregistrer un ordre d'achat",
  registerSellorder: "Enregistrer un ordre de vente",
  WalletAddress: "Adresse de portefeuille",
  ongoingOrders: "Commandes en cours",
  completedOrders: "Commandes terminées",
  SendMessageToBackup: "Envoyer un message à la sauvegarde du système",
  requestSubject: "Objet de la demande",
  message: "un message",
  sendMessage: "envoyer le message",
  EnterPassword: "Tapez votre mot de passe",
  forgotPassword: "J'ai oublié mon mot de passe",
  completeNumbers: "Veuillez compléter les numéros comme 123",
  LoginToSatrex: "Connectez-vous à Satrex",
  RegisterAtSatrex: "Inscrivez-vous chez Satrex",
  newUser: "Êtes-vous un nouvel utilisateur?",
  urlCheck: "Veuillez vérifier que vous avez entré à partir de cette adresse",
  mobile: "mobile",
  RepeatPassword: "Entrez à nouveau votre mot de passe",
  repeatPassword: "répétez le mot de passe",
  alreadyRegistered: "déjà enregistré ?",
  EnterAuthCode: "Entrer le code d'authentification",
  codeSentToMobile: "Code de confirmation envoyé au numéro de mobile",
  ResendCodeUp: "Renvoyer le code jusqu'à",
  resend: "renvoyer",
  continue: "Continuez",
  password: "Mot de passe",
  transactionValue: "La valeur de la transaction",
  BoardOfCompletedTransaction: "Conseil de la transaction terminée",
  theLowestPrice: "le prix le plus bas",
  theHighestPrice: "le prix le plus élevé",
  BuyAndSellOrders: "Ordres d'achat et de vente",
  Number: "Nombre",
  SendOrder: "Envoyer l'ordre",
  openOrders: "Commandes ouvertes",
  row: "Ligne",
  currencyName: "N  om de la devise",
  requestedCurrency: "Monnaie demandée",
  OrderType: "Type de commande",
  totalAmount: "Montant total",
  TheAmountFilled: "Le montant rempli",
  todayOrders: "Commandes d'aujourd'hui",
  cancelOrder: "Annuler la commande",
  "sureToCancelOrder?": "Êtes-vous sûr de vouloir annuler la commande?",
  marketsOf: "Marchés de",
  update: "mettre à jour",
  depositPageDescriptionLine1:
    "Pour augmenter le crédit de votre portefeuille Rial en utilisant les cartes bancaires des membres du réseau Shetab validées dans votre profil et via le portail de paiement en ligne.",
  depositPageDescriptionLine2:
    "Faites attention aux points suivants lors du paiement",
  depositPageDescriptionLine3:
    "Après avoir vérifié l'adresse de la page du portail bancaire et vous être assuré que l'adresse des sites du système Shaparak est correcte, entrez les coordonnées de votre carte bancaire et effectuez un dépôt.",
  depositPageDescriptionLine4:
    "Sur la page du portail, assurez-vous que le montant saisi est correct.",
  depositPageDescriptionLine5:
    "En déterminant le montant du dépôt, faites attention au fait que le montant minimum de la transaction sur le marché Satrex est de cent mille tomans.",
  depositWithdrawalCryptoPageLine1:
    "L'adresse de votre portefeuille est visible dans la case ci-dessous. Utilisez l'adresse affichée pour déposer des devises numériques dans ce sac.",
  depositWithdrawalCryptoPageLine2:
    "Si vous souhaitez retirer le solde de votre portefeuille, enregistrez votre demande ici.",
  securityPage1:
    "En activant l'authentification à deux facteurs pour vous connecter, en plus de votre nom d'utilisateur et de votre mot de passe, vous devrez également saisir un code à six chiffres. Pour générer ce code, vous utilisez une application qui sera installée sur votre téléphone. L'activation de l'authentification à deux facteurs augmente la sécurité de votre compte et il est recommandé de l'activer.",
  securityPageTableLine1:
    "Afin de protéger davantage votre compte, il est recommandé de prêter attention aux e-mails de notification de connexion qui vous sont envoyés. Vous pouvez également consulter l'historique des connexions réussies et infructueuses à votre compte.",
  destinationWalletAddressNote:
    "Noter; Assurez-vous que l'adresse que vous entrez est correcte, une mauvaise adresse entraînera la perte du montant de votre retrait.",
  YourWithdrawableInventory: "Votre inventaire récupérable",
  clipboardPermissionsError:
    "Impossible d'accéder au presse-papiers Veuillez demander l'autorisation",
  copied: "Copié",
  showAddress: "Afficher l'adresse",
  updateInventory: "Mettre à jour l'inventaire",
  operations: "Opérations",
  reports: "rapports",
  services: "Prestations de service",
  calculator: "calculatrice",
  introduceFriends: "présenter à des amis",
  apiDocs: "Documentation API",
  guide: "Guider",
  registerGuide: "Guide d'inscription",
  FAQ: "FAQ",
  authGuide: "Guide d'authentification et niveau utilisateur",
  priceGuide: "Aide prix et commissions",
  securityGuide: "Guide de sécurité du compte",
  satrex: "Satrex",
  aboutUs: "à propos de nous",
  contactus: "Contactez-nous",
  terms: "Termes et conditions d'utilisation",
  satrexAddress:
    "Complexe commercial Mashhad-Sayad Shirazi Boulevard-Stasenter",
  onSocialMedia: "Nous sommes sur les réseaux sociaux",
  satrexRights:
    "Les droits matériels et intellectuels de ce site appartiennent à Satorex et toute copie de celui-ci sera poursuivie.",
  AboutSatrex: "À propos de Satrex",
  headOfficeInfo: "Coordonnées du siège social",
  phone: "Téléphone",
  openingHours: "Horaires d'ouvertures",
  openingHoursSatrex: "du samedi au mercredi de 8h30 à 17h",
  contactForm: "formulaire de contact",
  firstNameAndLastName: "prénom et nom",
  contactNumber: "Numéro de téléphone",
  marketCap: "capitalisation boursière",
};

export default (props) => {
  return dic[props];
};
