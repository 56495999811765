import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle, IonPage
} from "@ionic/react";
import Trans from "./Trans"
import { useLocation } from "react-router-dom";
import {
  chevronBackOutlin, chevronForwardOutlinee, chevronForwardOutline, chevronBackOutline
} from "ionicons/icons";
import "./Menu.css";
import logo from "../images/logo.png";
import logoText from "../images/loginComponent/logoBlackText.svg";
import React from "react";
import Icon1 from "../images/sidbar/1.png"
import Icon2 from "../images/sidbar/2.png"
import Icon3 from "../images/sidbar/3.png"
import Icon4 from "../images/sidbar/4.png"
import Icon5 from "../images/sidbar/5.png"
import Icon6 from "../images/sidbar/6.png"
import Icon7 from "../images/sidbar/7.png"
import Icon8 from "../images/sidbar/8.png"
import Icon9 from "../images/sidbar/9.png"
import Icon10 from "../images/sidbar/10.png"
import Icon11 from "../images/sidbar/11.png"
import { useSelector } from "react-redux";


interface AppPage {
  url: string;
  iosIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {title: "dashboard", url: "/home", iosIcon: Icon1},
  {title: "wallet", url: "/wallet", iosIcon: Icon2},
  {title: "markets", url: "/markets", iosIcon: Icon3},
  {title: "accounting", url: "/accounting", iosIcon: Icon4},
  {title: "profile", url: "/profile", iosIcon: Icon5},
  {title: "security", url: "/security", iosIcon: Icon6},
  {title: "quickDealing", url: "/fast-dealing", iosIcon: Icon7},
  {title: "report", url: "/reports", iosIcon: Icon8},
  {title: "support", url: "/support", iosIcon: Icon9},
  {title: "contactus", url: "/contact-us", iosIcon: Icon10},
  {title: "exit", url: "/login", iosIcon: Icon11},
];


const Menu: React.FC = () => {
  const location = useLocation();

  const language = useSelector(state=>state.layout.language)
  const isLtr = language === 'EN';


  return (
      <IonMenu side='start' contentId="main"  type="overlay" >
        <IonContent dir={isLtr ? 'ltr' : 'rtl'} style={{top:0}}>
          <IonList id="inbox-list" style={{paddingTop:10,borderBottom:'none'}}>
            <IonListHeader>
              <div style={{flexDirection:'row',position:'relative'}}>
                <img src={logo} style={{width:30}} alt=""/>
                <img src={logoText} style={{width:120,position:'relative',top:5}} alt=""/>

              </div>
            </IonListHeader>
            <div style={{borderTop:'1px solid #d7d8da',width:'92%',marginTop:12}}/>
            {appPages.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    className={`${location.pathname === appPage.url ? "selected" : ""} navItem`}
                    routerLink={appPage.url}
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <img src={appPage.iosIcon} style={{width:30}} alt=""/>&nbsp;&nbsp;&nbsp;
                    <IonLabel><span style={{fontSize:13}}><Trans>{appPage.title}</Trans></span></IonLabel>
                    <IonIcon icon={isLtr ?  chevronForwardOutline : chevronBackOutline} style={{width:15}}/>

                  </IonItem>
                  <div style={{borderTop:'1px solid #d7d8da',width:'92%'}}/>
                </IonMenuToggle>
              );
            })}
          </IonList>
        </IonContent>
      </IonMenu>
  );
};

export default Menu;
