import { useQuery } from 'react-query';
import {
  getAddressInfo,
  getBankAccounts,
  getCities,
  getCoinList,
  getCompletedOrdrers,
  getCurrentOrders,
  getDepartments, getEmail,
  getFastExchangePrice,
  getHighLowPrice,
  getLoginHistory,
  getMarketsInfo,
  getMaximumWithdrawalPerDayInToman,
  getOpenOrderList,
  getOtpStatus,
  getPersonalInfo,
  getProvices,
  getQualifyLevel,
  getReportFastOrders,
  getReportOpenOrders,
  getReportOrderList,
  getRequestList, getSampleUserImages,
  getTodaysOrders,
  getTotalBalance,
  getTotalFund,
  getTotalFundAndTradable,
  getTurnoverList,
  getUserFee,
  getUserImage,
  getUserMessages,
  getWalletInfo, getWalletSummary,
} from './apis';

const cacheTime = 100000;

export const useBankAccounts = () => useQuery('bankAccounts', getBankAccounts, {
  cacheTime,
});

export const useWalletList = () => useQuery('getWalletInfo', getWalletInfo, {
  cacheTime,
});

export const useWalletSummary = () => useQuery('getWalletSummary', getWalletSummary, {
  cacheTime,
});

export const useMessages = () => useQuery('getUserMessages', getUserMessages, {
  cacheTime,
});

export const useMarketList = () => useQuery('getMarketsInfo', getMarketsInfo, {
  cacheTime,
});
export const useOpenOrders = (pairId) => useQuery(['getOpenOrderList', pairId], () => getOpenOrderList(pairId), {
  cacheTime,
});

export const useHighLowPrice = (id) => useQuery(['getMarketsInfo', id], () => getHighLowPrice(id), {
  cacheTime,
});

export const useCompletedOrders = (id) => useQuery(['getCompletedOrdrers', id], () => getCompletedOrdrers(id), {
  cacheTime,
});

export const useCurrentOrders = (id) => useQuery(['getCurrentOrders', id], () => getCurrentOrders(id), {
  cacheTime,
});

export const useTotalBalance = (id) => useQuery(['getTotalBalance', id], () => getTotalBalance(id), {
  cacheTime,
});

export const useTodayOrders = (id) => useQuery('getTodaysOrders', () => getTodaysOrders(id), {
  cacheTime,
});

export const useLoginHistory = () => useQuery('getLoginHistory', getLoginHistory, {
  cacheTime,
});

export const useReportOrderList = () => useQuery('getReportOrderList', getReportOrderList, {
  cacheTime,
});

export const useReportOpenOrders = () => useQuery('getReportOpenOrders', getReportOpenOrders, {
  cacheTime,
});

export const useReportFastOrders = () => useQuery('getReportFastOrders', getReportFastOrders, {
  cacheTime,
});

export const useUserFee = () => useQuery('getUserFee', getUserFee, {
  cacheTime,
});

export const useTotalFund = () => useQuery('getTotalFund', getTotalFund, {
  cacheTime,
});

export const useCoinList = () => useQuery('getCoinList', getCoinList, {
  cacheTime,
});
export const useFastExchangePrice = (id) => useQuery(['getFastExchangePrice', id], () => getFastExchangePrice(id), {
  cacheTime,
});

export const useDepartments = () => useQuery('getDepartments', getDepartments, {
  cacheTime,
});

export const useQualifyLevel = () => useQuery('getQualifyLevel', getQualifyLevel, {
  cacheTime,
});

export const useTurnoverList = () => useQuery('getTurnoverList', getTurnoverList, {
  cacheTime,
});

export const useRequestsList = () => useQuery('getRequestList', getRequestList, {
  cacheTime,
});

export const usePersonalInfo = () => useQuery('personalInfo', getPersonalInfo, {
  cacheTime,
});

export const useUserImages = () => useQuery('getUserImage', getUserImage, {
  cacheTime,
});

export const useProvinces = () => useQuery('getProvices', getProvices, {
  cacheTime,
});

export const useCities = (id) => useQuery(['getCities', id], () => getCities(id), {
  cacheTime,
});

export const useAddressInfo = (id) => useQuery('getAddressInfo', getAddressInfo, {
  cacheTime,
});

export const useTotalFundAndTradable = (id) => useQuery('getTotalFundAndTradable', getTotalFundAndTradable, {
  cacheTime,
});

export const useMaximumWithdrawalPerDayInToman = (id) => useQuery('getMaximumWithdrawalPerDayInToman', getMaximumWithdrawalPerDayInToman, {
  cacheTime,
});

export const useOptStatus = () => useQuery('getOtpStatus', getOtpStatus, {
  cacheTime,
});

export const useUserEmail = () => useQuery('userEmail', getEmail, {
  cacheTime,
});

export const useSampleUserImage = () => useQuery('sampleUserImage', getSampleUserImages, {
  cacheTime,
});
