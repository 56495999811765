import axios from 'axios';
import moment from 'moment';
import axiosRetry from 'axios-retry';
import starkString from 'starkstring';
import { createBrowserHistory } from 'history';
import { addRedirection } from '../redux/actions/LayoutActions';
import { Store } from '../redux/store';
import { setJwtToken, setRefreshTokenTime } from '../redux/actions/LoginActions';

export const refreshTokenUrl = '/Token/Refresh';

const requestHeader = axios.create({
  baseURL: 'https://enduserapi.satrex.ir/api/v1',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept-language': 'fa-IR',
    'Access-Control-Allow-Origin': '*',
  },
});

export const authRoutes = (route) => {
  switch (route) {
    case '/login':
      return true;
    case '/register':
      return true;
    case '/reset-password':
      return true;
    default:
      return false;
  }
};

export default requestHeader;

export const defaultApi = () => {
  delete requestHeader.defaults.headers.common.Authorization;
  return requestHeader;
};

export const satrexApi = () => requestHeader;

export const baseUrl = 'https://enduserapi.satrex.ir';

axiosRetry(axios, {
  retries: 3, // number of retries
  retryDelay: (retryCount) => retryCount * 2000, // time interval between retries
  retryCondition: (error) => {
    console.log('this fn is calling ');
    // if retry condition is not specified, by default idempotent requests are retried
    return error.response.status === 401 || !window.navigator.onLine;
  },
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      // console.log('token from promise : ', token);
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

requestHeader.interceptors.request.use(
  (config) => {
    let token;
    if (Store.getState().login.token) {
      token = Store.getState().login.token.token;
    } else {
      token = localStorage.getItem('bearerToken');
    }
    console.log('history.location : ', authRoutes(createBrowserHistory().location.pathname));
    console.log('config : ', config.url);
    config.headers.authorization = (authRoutes(createBrowserHistory().location.pathname) || config.url === refreshTokenUrl) ? null : `bearer ${token}`;
    return config;
  },
);

satrexApi().interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    // history.replace('no-internet')
    if (!window.navigator.onLine) {
      Store.dispatch(addRedirection({ fromUrl: '/*', toUrl: '/no-internet' }));
      // console.log('navigating !!!!');
      // return false;
      originalRequest._retry = false;
      setTimeout(() => {
        satrexApi()(originalRequest);
      }, 10000);
    }

    if (error.response !== undefined && error.response.status === 400) {
      error.response.status = 200;
      Store.dispatch(addRedirection({ fromUrl: '/*', toUrl: '/login' }));
      // console.log(error.response.data);
    }
    if (error.response !== undefined && error.response.status === 401 && !originalRequest._retry && Store.getState().login.token) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      isRefreshing = true;
      originalRequest._retry = true;

      return new Promise((resolve, reject) => {
        requestHeader
          .post(refreshTokenUrl, {
            refreshToken: Store.getState().login.token.refreshToken || localStorage.getItem('refreshToken'),
          },
            {
              headers: {
                authorization: '',
              },
            })
          .then((res) => {
            if (res.status === 201 || res.status === 200) {
              localStorage.removeItem('refreshToken');
              console.log('result : ', res.data);
              if (!res.data.isSuccess) {
                processQueue(error, null);
                console.log('شما به دلیل خطا در رفرش توکن به بیرون هدایت شدید');
                localStorage.removeItem('bearerToken');
                localStorage.removeItem('refreshToken');
                delete axios.defaults.headers.common.Authorization;
                Store.dispatch(addRedirection({ fromUrl: '/*', toUrl: '/login' }));
                createBrowserHistory().push('/login');
                reject();
              } else {
                satrexApi().defaults.headers.common.Authorization = `Bearer ${res.data.data.jwtToken.tokenValue}`;

                localStorage.setItem(
                  'bearerToken',
                  res.data.data.jwtToken.tokenValue,
                );
                Store.dispatch(setJwtToken(
                  res.data.data.jwtToken.tokenValue,
                  res.data.data.refreshToken.tokenValue,
                ));
                Store.dispatch(setRefreshTokenTime(
                  starkString(
                    moment()
                      .add(
                        res.data.data.jwtToken.expiresInSeconds + 120,
                        'seconds',
                      )
                      .format('DD/MM/YYYY HH:mm:ss'),
                  )
                    .englishNumber()
                    .toString(),
                ));
                localStorage.setItem(
                  'expirationDate',
                  starkString(
                    moment()
                      .add(
                        res.data.data.jwtToken.expiresInSeconds + 120,
                        'seconds',
                      )
                      .format('DD/MM/YYYY HH:mm:ss'),
                  )
                    .englishNumber()
                    .toString(),
                );

                localStorage.setItem(
                  'refreshToken',
                  res.data.data.refreshToken.tokenValue,
                );
                originalRequest.headers.Authorization = `bearer ${res.data.data.jwtToken.tokenValue}`;
                processQueue(null, res.data.data.jwtToken.tokenValue);
                resolve(axios(originalRequest));
              }
            } else {
              localStorage.removeItem('bearerToken');
              localStorage.removeItem('refreshToken');
              delete axios.defaults.headers.common.Authorization;
              // Store.dispatch(addRedirection({ fromUrl: '/*', toUrl: '/login' }));
              createBrowserHistory().push('/login');
              // Store.dispatch(addRedirection({ fromUrl: '/*', toUrl: '/login' }));
            }
          })
          .catch((err) => {
            localStorage.removeItem('bearerToken');
            localStorage.removeItem('refreshToken');
            delete axios.defaults.headers.common.Authorization;
            // Store.dispatch(addRedirection({ fromUrl: '/*', toUrl: '/login' }));
            createBrowserHistory().push('/login');
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  },
);
