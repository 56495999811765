import React, { useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import { helpCircleOutline } from "ionicons/icons";
import Tour from "reactour";


const TourGuide = ({tourConfig,cssClass,firstLoad}) => {

  const [isTourOpen,setIsTourOpen] = useState(false);

  const closeTour = ()=>{
    setIsTourOpen(false)
  }
  const openTour = ()=>{
    setIsTourOpen(true)
  }


  useEffect(()=>{
    if(firstLoad !== undefined && firstLoad === false)
      setTimeout(()=>{
        setIsTourOpen(true)
      },1000)
    // console.log('firstLoad changed : ',firstLoad);
    // console.log('isOpen : ',isTourOpen);
  },[firstLoad])

  return (
    <>
        <IonIcon icon={helpCircleOutline} onClick={openTour} className={`guideButton ${cssClass}`} />
      <Tour
        startAt={0}
        onRequestClose={closeTour}
        steps={tourConfig}
        isOpen={isTourOpen}
        maskClassName="mask"
        className="helper"
        rounded={5}
        accentColor="#5cb7b7"
      />
    </>
  );
};

export default TourGuide;