import { ADD_REDIRECTS, CLEAN_REDIRECTS, USER_LANGUAGE } from '../actions/LayoutActions';

const initialState = {
    language: 'FA',
    redirectUrls: [],
};

const layoutReducer = function (state = initialState, action) {
    switch (action.type) {
        case USER_LANGUAGE: {
            return {
                ...state,
                language: action.payload,
            };
        }
        case ADD_REDIRECTS: {
            return {
                ...state,
                redirectUrls: [...state.redirectUrls, action.payload],
            };
        }
        case CLEAN_REDIRECTS: {
            return {
                ...state,
                redirectUrls: [],
            };
        }
        default:
            return state;
    }
};

export default layoutReducer;
