import React from 'react';

export default [
  {
    selector: '[data-tut="coinMarketDetail"]',
    content: () => (
      <div>
        <p>جزئیات کوین را میتوانید در این قسمت مشاهده کنید</p>
      </div>
    ),
  },
  {
    selector: '[data-tut="tradingviewChart"]',
    content: () => (
      <div>
        <p>چارت مربوط به مارکت را میتوانید در این بخش مشاهده کنید</p>
        <p>
<span style={{ color: 'red' }}>نکته: </span>
<span>مارکت های مربوط به تومن چارت های آن ها با USD نمایش داده میشود</span>
        </p>
      </div>
    ),
  },

  {
    selector: '[data-tut="availMarketList"]',
    content: () => (
      <div>
        <p>لیست مارکت های موجود را در این بخش میتوانید مشاهده کنید</p>
      </div>
    ),
  },

  {
    selector: '[data-tut="marketCompletedOrders"]',
    content: () => (
      <div>
        <p>تمامی سفارشات انجام شده خود را میتوانید در این بخش مشاهده کنید</p>
      </div>
    ),
  },
  {
    selector: '[data-tut="marketSetOrder"]',
    content: () => (
      <div>
        <p>برای هدایت به صفحه ی مربوط به ثبت سفارش از این بخش استفاده کنید</p>
      </div>
    ),
  },

];
