import React from 'react';

const dic = {
  dashboard: 'داشبورد',
  wallet: 'کیف پول',
  markets: 'بازارها',
  quickDealing: 'معامله سریع',
  systemGuide: 'راهنمای سیستم',
  today: 'امروز',
  BackToDashboard: 'بازگشت به داشبورد',
  profile: 'پروفایل',
  security: 'امنیت',
  accounting: 'حسابداری',
  report: 'گزارشات',
  support: 'پشتیبانی',
  exit: 'خروج',
  NegotiableCapital: 'سرمایه قابل معامله',
  TomanCapital: 'سرمایه تومانی',
  SatrexDayTradingVolume: 'حجم معاملات روز ساترکس',
  editPersonalInfo: 'ویرایش اطلاعات شخصی',
  legal: 'حقوقی',
  natural: 'حقیقی',
  user: 'کاربر',
  level: 'سطح',
  2: <span className=" " style={{ color: 'white' }}>2</span>,
  woman: 'خانم',
  man: 'آقا',
  firstName: 'نام',
  lastName: 'نام خانوادگی',
  nationalId: 'کدملی',
  birthDate: 'تاریخ تولد',
  email: 'ایمیل',
  postCode: 'کد پستی',
  address: 'آدرس',
  telephone: 'تلفن ثابت',
  phoneNumber: 'تلفن همراه',
  walletStatus: 'وضعیت کیف پول',
  uploadNationalCardImage: 'بارگزاری تصویر کارت ملی ',
  pictureOnNationalCard: 'تصویر روی کارت ملی',
  sendPersonalPhotoAsPattern: 'ارسال تصویر شخصی مطابق نمونه',
  saveInfo: 'ذخیره اطلاعات',
  enable: 'فعال',
  disable: 'غیرفعال',
  seeSample: 'مشاهده نمونه',
  dragAndDropImageOrClick: 'تصویر را بکشید و رها کنید یا کلیک کنید',
  buy: 'خرید',
  sell: 'فروش',
  depositAndWithdrawCrypto: 'واریز و برداشت رمز ارز',
  deposit: 'واریز وجه',
  withdrawal: 'برداشت وجه',
  turnover: 'گردش حساب',
  addCard: 'افزودن کارت',
  addBankAccount: 'افزودن حساب بانکی',
  requests: 'درخواست ها',
  editProfile: 'ویرایش اطلاعات شخصی',
  editBankInfo: 'ویرایش اطلاعات بانکی',
  messages: 'پیام ها',
  personalInfo: 'اطلاعات شخصی',
  contactInfo: 'اطلاعات تماس',
  sendingDocs: 'ارسال مدارک',
  tether: 'تتر',
  UserLevelUpgrade: 'ارتقا سطح کاربری',
  YourUserlevel: 'سطح کاربری شما',
  YourTransactionFee: 'کارمزد معاملات شما',
  DailyWithdrawalCeiling: 'سقف برداشت وجه روزانه',
  DailyCryptoWithdrawalCeiling: 'سقف برداشت روزانه رمزارز',
  rial: 'ریال',
  NumberOfOpenOrders: 'تعداد سفارشات باز',
  ValueOfTransactionsInTheLast30Days: 'ارزش معاملات ۳۰ روز گذشته',
  NumberOfOpenBuyOrders: 'تعداد سفارشات خرید باز',
  NumberOfOpenSellOrders: 'تعداد سفارشات فروش باز',
  YourTotalAssets: 'مجموع دارایی شما',
  WalletStatus: 'وضعیت کیف پول',
  MarketsInTheLast24Hours: 'بازارها در ۲۴ ساعت گذشته',
  searchCrypto: 'جستجوی رمزارز',
  mostGrowth: 'بیشترین رشد',
  mostFalls: 'بیشترین ریزش',
  BasedOnMarketRanking: 'براساس رتبه بازار',
  BasedOnMarketCap: 'براساس حجم معاملات',
  cryptocurrency: 'رمزارز',
  price: 'قیمت',
  changes: 'تغییرات',
  totalSupply: 'سکه در گردش',
  marketCap: 'حجم بازار',
  more: 'بیشتر',
  inventory: 'موجودی',
  AverageBuy: 'میانگین خرید',
  PriceInTomans: 'قیمت به تومان',
  PriceInTether: 'قیمت به تتر',
  '24Change': 'تغییر در ۲۴ ساعت',
  market: 'بازار',
  systemExit: 'خروج از سیستم',
  sureToGetOut: 'اطمینان دارید که از سیستم خارج میشوید؟',
  cancel: 'انصراف',
  yes: 'بله',
  Deposit: 'واریز',
  chooseCrypto: 'انتخاب رمز ارز',
  WithdrawalAmount: 'مقدار برداشت',
  destinationWalletAddress: 'آدرس کیف پول مقصد',
  TwoFactorCode: 'کد شناسایی دوعاملی',
  requestSMScode: 'درخواست کد پیامکی',
  transferFee: 'کارمزد انتقال',
  SubmitWithdrawalRequest: 'ثبت درخواست برداشت',
  depositCryptocurrency: 'برداشت رمزارز',
  walletBalance: 'موجودی کیف پول',
  bankCard: 'کارت بانکی',
  depositAmount: 'مبلغ واریزی',
  yourDepositCeiling: 'سقف واریزشما',
  TransferBankPortal: 'انتقال به درگاه بانکی',
  withdrawalTime: 'زمان برداشت',
  confirm: 'تایید',
  clear: 'پاک کردن',
  withdrawalDateNote: 'نکته : تاریخ برداشت فقط میتواند از روز کاری آتی باشد',
  withdrawalAmount: 'مبلغ برداشت',
  WithdrawalTotalInventory: 'برداشت کل موجودی',
  DestinationAccountNumber: 'شماره حساب مقصد',
  BankingNetworkTransferFee: 'کارمزد انتقال شبکه بانکی',
  id: 'شناسه',
  date: 'تاریخ',
  time: 'ساعت',
  type: 'نوع',
  currency: 'ارز',
  fee: 'کارمزد',
  Withdrawal: 'برداشت',
  transactionDetails: 'جزئیات تراکنش',
  symbol: 'نماد',
  bankTrackingID: 'شناسه پیگیری بانک',
  fullName: 'نام و نام خانوادگی',
  requestDay: 'روز درخواست',
  status: 'وضعیت',
  description: 'توضیحات',
  bankCardNumber: 'شماره کارت بانکی',
  bankIbanNumber: 'شماره شبای بانکی',
  bankName: 'نام بانک',
  awaitingApproval: 'در انتظار تایید',
  accepted: 'تایید شده',
  rejected: 'رد شده',
  meliBank: ' ملی ایران',
  sepahBank: ' سپه',
  toseBank: ' توسعه صادرات',
  sanatBank: ' صنعت و معدن',
  keshavarziBank: ' کشاورزی',
  maskanBank: ' مسکن',
  postBank: 'پست بانک ایران',
  toseTavonBank: ' توسعه تعاون',
  eghtesadBank: ' اقتصاد نوین',
  parsianbank: ' پارسیان',
  pasargardBank: ' پاسارگاد',
  karafarinBank: ' کارآفرین',
  samanbank: ' سامان',
  sinaBank: ' سینا',
  sarmayeBank: ' سرمایه',
  tatBank: ' تات',
  shahrBank: ' شهر',
  deyBank: ' دی',
  saderatBank: '  صادرات',
  melatBank: ' ملت',
  tejarat: ' تجارت',
  refahbank: ' رفاه',
  ansarBank: ' انصار',
  mehrEghtesadBank: ' مهر اقتصاد',
  bank: 'بانک',
  zeroLevelUser: 'کاربر سطح صفر',
  oneLevelUser: 'کاربر سطح یک',
  twoLevelUser: 'کاربر سطح دو',
  authentication: 'احراز هویت',
  bankInfo: 'اطلاعات بانکی',
  personalImage: 'عکس شخصی',
  adminRejection: 'اطلاعات وارد شده در این بخش مورد تایید ادمین نبود ! لطفا بعد از ویرایش مجددا ارسال کنید',
  adminAcceptation: 'اطلاعات این بخش مورد تایید ادمین قرار گرفته است',
  adminPending: 'اطلاعات این بخش برای ادمین ارسال شده و در انتظار تایید میباشد',
  cancelRequest: 'لغو درخواست',
  province: 'استان',
  city: 'شهر',
  submitInformation: 'ارسال اطلاعات',
  submitInfoAsk: ' درصورتی که از درستی اطلاعات خود اطمینان دارید "ارسال" را کلیک کنید',
  save: 'ذخیره',
  saveAndSubmit: 'ذخیره و ارسال',
  varifyEmailCode: 'کد صحت سنجی به ایمیل شما ارسال شد',
  send: 'ارسال',
  validationCode: 'کد صحت سنجی',
  code: 'کد',
  topic: 'موضوع',
  sendDate: 'تاریخ ارسال',
  changePassword: 'تغییر کلمه عبور',
  enterCurrentPassword: 'کلمه عبور فعلی خود را وارد کنید',
  EnterNewPassword: 'کلمه عبور جدید خود را وارد کنید',
  repeatNewPassword: 'تکرار کلمه عبور جدید خود را وارد کنید',
  activeTwoFactorIdentification: 'فعال سازی شناسایی دومرحله ایی',
  ActivateGoogleAuth: 'فعال سازی گوگل آتنتیکیتور',
  EnableLoginSMS: 'فعالسازی پیامک ورود',
  LoginLogoutInformation: 'اطلاعات ورود و خروج',
  browserId: 'شناسه مرورگر',
  Ip: 'آی پی',
  LoginLogout: 'ورود و خروج',
  ViewFinancialReports: 'مشاهده گزارشات مالی',
  AmountInRials: 'مبلغ به ریال',
  networkFee: 'کارمزد شبکه',
  maxBuy: 'حداکثر مقدار خرید شما',
  registerBuyorder: 'ثبت سفارش خرید',
  registerSellorder: ' ثبت سفارش فروش',
  WalletAddress: 'آدرس کیف پول',
  ongoingOrders: 'سفارشات در حال انجام',
  completedOrders: 'سفارشات انجام شده',
  SendMessageToBackup: 'ارسال پیام به پشتیبان سیستم',
  requestSubject: 'موضوع درخواست',
  message: 'پیام',
  sendMessage: 'ارسال پیام',
  EnterPassword: 'رمز عبور خود را وارد کنید',
  forgotPassword: 'رمز عبور خودرا فراموش کرده ام',
  completeNumbers: 'لطفا اعداد را به صورت 123 کامل کنید',
  LoginToSatrex: ' ورود به ساترکس',
  RegisterAtSatrex: 'ثبت نام در ساترکس',
  'newUser?': 'کاربرجدید هستید؟',
  urlCheck: 'لطفا بررسی کنید که از این آدرس وارد شدید',
  mobile: 'موبایل',
  RepeatPassword: 'رمز عبور خود را مجدداً کنید',
  repeatPassword: 'تکرار رمز عبور',
  'alreadyRegistered?': 'اخیراً ثبت نام کرده اید؟',
  EnterAuthCode: 'کد تایید را وارد کنید',
  codeSentToMobile: 'Confirmation code sent to mobile number',
  ResendCodeUp: 'ارسال مجدد کد تا',
  resend: 'ارسال مجدد',
  continue: 'ادامه',
  password: 'رمز عبور',
  transactionValue: 'ارزش معامله',
  BoardOfCompletedTransaction: 'تابلو معاملات انجام شده',
  theLowestPrice: 'پایین ترین قیمت',
  theHighestPrice: 'بالاترین قیمت',
  BuyAndSellOrders: 'سفارشات خرید و فروش',
  Number: 'تعداد',
  SendOrder: 'ارسال سفارش',
  openOrders: 'سفارشات باز',
  row: 'ردیف',
  currencyName: 'نام ارز',
  requestedCurrency: 'ارز درخواستی',
  OrderType: 'نوع سفارش',
  totalAmount: 'مبلغ کل',
  TheAmountFilled: 'مقدار پر شده',
  todayOrders: 'سفارشات امروز',
  cancelOrder: 'لغو سفارش',
  'sureToCancelOrder?': 'آیا از لغو سفارش اطمینان دارید؟',
  marketsOf: 'بازارهای',
  update: 'بروزرسانی',
  depositPageDescriptionLine1: 'جهت افزایش اعتبار کیف پول ریالی خود با استفاده از کارت‌های بانکی عضو شبکه شتاب تایید شده در پروفایلتان و از طریق درگاه پرداخت اینترنتی اقدام نمایید.',
  depositPageDescriptionLine2: 'در هنگام پرداخت به نکات زیر دقت نمایید',
  depositPageDescriptionLine3: 'پس از بررسی آدرس صفحه‌ی درگاه بانکی و اطمینان از صحت آدرس سایت‌های سامانه‌ی شاپرک مشخصات کارت بانکی خود را وارد و اقدام به واریز وجه نمایید',
  depositPageDescriptionLine4: 'در صفحه درگاه دقت کنید که مبلغ وارد شده درست باشد.',
  depositPageDescriptionLine5: 'در تعیین مبلغ واریز به این موضوع دقت نمایید که حداقل مبلغ معامله در بازار ساترکس صد هزار تومان است.',
  depositWithdrawalCryptoPageLine1: 'آدرس  کیف پول شما در کادر زیر قابل مشاهده است. برای واریز ارزهای دیجیتال به این کیف، از آدرس نمایش داده شده استفاده کنید.',
  depositWithdrawalCryptoPageLine2: 'در صورت تمایل به برداشت موجودی کیف پول‌های خود، درخواست خود را اینجا ثبت نمایید.',
  securityPage1: 'با فعال‌سازی شناسایی دوعاملی برای ورود به سامانه، علاوه بر نام کاربری و گذرواژه، نیاز به وارد کردن یک کد شش رقمی نیز خواهید داشت. برای تولید این کد از یک اپ که روی گوشی شما نصب خواهد شد، استفاده می‌کنید. فعال‌سازی شناسایی دوعاملی باعث افزایش امنیت حساب شما می‌شود و توصیه می‌شود که حتما آن را فعال کنید.',
  securityPageTableLine1: 'به منظور حفاظت بیش‌تر از حساب خود، پیشنهاد می‌شود که به ایمیل‌های اطلاع‌رسانی ورود به سایت که برای شما ارسال می‌شود، دقت نمایید. همچنین می‌توانید تاریخچه‌ی لاگین‌های موفق و ناموفق به حساب خود را مشاهده کنید.',
  destinationWalletAddressNote: 'توجه: مطمئن شوید آدرس وارد شده صحیح است ، آدرس اشتباه منجر به از دست دادن مبلغ برداشت شما می شود.',
  YourWithdrawableInventory: 'موجودی قابل برداشت شما',
  clipboardPermissionsError: 'ناتوان در دسترسی به کلیپبورد لطفا مجوز آن را اعمال کنید',
  copied: 'کپی شد',
  showAddress: 'نمایش آدرس',
  updateInventory: 'بروزرسانی موجودی',
  operations: 'عمليات',
  reports: 'گزارشات',
  services: 'خدمات',
  calculator: 'ماشین حساب',
  introduceFriends: 'معرفی به دوستان',
  apiDocs: 'مستندات API',
  guide: 'راهنما',
  registerGuide: 'راهنمای ثبت نام',
  FAQ: 'سوالات متداول',
  authGuide: 'راهنمای احراز هویت و سطح کاربری',
  priceGuide: 'راهنما قیمت ها و کارمزدها',
  securityGuide: 'راهنمای امنیت حساب کاربری',
  satrex: 'ساترکس',
  aboutUs: 'درباره ما',
  contactus: 'ارتباط با ما',
  terms: 'قوانین و شرایط استفاده',
  satrexAddress: 'مشهد-بلوار صیادشیرازی-مجتمع تجاری ستاسنتر',
  onSocialMedia: 'ما در شبکه های اجتماعی ',
  satrexRights: 'حقوق مادی و معنوی این سایت متعلق به ساترکس می باشد و هرگونه کپی برداری از آن پیگرد قانونی دارد.',
  AboutSatrex: 'درباره ساتکرس',
  headOfficeInfo: 'اطلاعات تماس دفتر مرکزی',
  phone: 'تلفن',
  fax: 'فکس',
  openingHours: 'ساعت کاری',
  openingHoursSatrex: 'شنبه تا چهار شنبه از ساعت ۸:۳۰ الی ۱۷',
  contactForm: 'فرم تماس با ما',
  firstNameAndLastName: 'نام و نام خانوادگی',
  contactNumber: 'شماره تماس',
  toman: 'تومان',
  WaitingForSupportUserReply: 'درانتظار جواب پشتیبان',
  AnsweredBySupportUser: 'پاسخ داده شده توسط پشتیبان',
  ClosedByEndUser: 'بسته شده توسط کاربر',
  ClosedBySupportUser: 'بسته شده توسط پشتیبان',
  ClosedBySystem: 'بسته شده توسط سیستم',
  High: 'بالا',
  Normal: 'متوسط',
  Low: 'پایین',
  Rejected: 'رد شده',
  Pending: 'در انتظار تایید',
  AcceptedAndPaid: 'تایید و پرداخت شد',
  CanceledByUser: 'کنسل شده توسط کاربر',
  bitCoin: 'بیت کوین',
  DogeCoin: 'دوج کوین',
  One: 'یک',
  ChangesIn24Hours: 'تغییرات در 24 ساعت',
  MarketRank: 'رتبه بازار',
  'DepositAmount(Toman)': 'مبلغ واریزی(تومان)',
  'YourDepositLimit(Toman)': 'سقف واریز شما(تومان)',
  RegisterYourRialBalanceWithdrawalRequest: 'درخواست برداشت موجودی ریالی خود را ثبت کنید.',
  withdrawalPageDescriptionLine2: 'در صورت ثبت حساب بانک سامان و استفاده از آن، امکان انتقال داخل بانکی و تسریع درخواست برداشت امکانپذیر خواهد بود. ',
  SaterexRialSettlementSchedule: 'زمانبندی تسویه ریالی ساترکس',
  'Inventory(Toman)': 'موجودی (تومان)',
  'WithdrawalAmount(Tomans)': 'مبلغ برداشت (تومان)',
  TotalDailyWithdrawals: 'مجموع برداشت روزانه',
  TotalMonthlyWithdrawals: 'مجموع برداشت ماهانه',
  SelectOneOfYourApprovedBankAccountsToReceiveTheMoney: 'یکی از حساب‌های بانکی تایید شده‌ی خود را برای دریافت وجه انتخاب نمایید.',
  RequestHours: 'ساعت درخواست',
  activities: 'عملیات',
  All: 'همه',
  Daily: 'روزانه',
  Weekly: 'هفتگی',
  monthly: 'ماهانه',
  'YouAreInTheAuthenticationPhase.': 'شما در مرحله ی احراز هویت هستید.',
  YourContactInformationHasBeenSentToTheAdminAndIsAwaitingReview: 'اطلاعات تماس شما برای ادمین ارسال شده و در انتظار بررسی قرار گرفته است',
  YourPhotosHaveNotBeenUploadedYet: 'عکس های شما هنوز ارسال نشده است',
  AccountStatus: 'وضعیت حساب کاربری',
  ApprovedLevel: ' تایید شده سطح',
  UserLevel: 'سطح کاربری',
  '500thousandTomansPerDay': '500 هزار تومان در روز',
  RialWithdrawalCeiling: 'سقف برداشت ریالی',
  RialDepositCeiling: 'سقف واریز ریالی',
  '20millionTomansPerDay': ' 20 میلیون تومان در روز',
  RialAndRamzarzHarvestCeiling: 'سقف برداشت ریال و رمزارز',
  '100millionTomansPerDay': '100 میلیون تومان در روز',
  UpgradeYourUserInterfaceToCompleteTheAuthenticationProcessAndEnjoyItsBenefits: 'با تکمیل فرآیند احراز هویت سطح کاربری خود را ارتقا دهید و از مزایای آن بهره مند شوید.',
  HelpVerifyYourIdentity: 'راهنما احرازهویت',
  TheFirstStage: 'مرحله ی اول',
  LastReplyDate: 'تاریخ آخرین پاسخ',
  FinancialReports: 'گزارشات مالی',
  orders: 'سفارشات',
  'UnitPrice(Tomans)': 'قیمت واحد(تومان)',
  'TotalAmount(Tomans)': 'مبلغ کل(تومان)',
  NoteToViewYourQuickOrderListYouCanReferToTheReportsSection: 'نکته : برای مشاهده لیست سفارشات سریع خود میتوانید به بخش گزارشات مراجعه کنید',
  FastOrders: 'سفارشات سریع',
  FastDeals: 'معاملات سریع',
  Priority: 'اولویت',
  RelevantDepartment: 'دپارتمان مربوطه',
  technicalSupport: 'پشتیبانی فنی',
  Financial: 'مالی',
  amount: 'میزان',
  Price: 'قیمت',
  GettingStartedInSatrex: 'شروع به کار در ساترکس',
  helpPageDescriptionLine1: 'پس از ثبت نام و تایید حساب کاربری ، می توانید حساب خود را به صورت ریالی و یا رمزارز شارژ کرده و از معامله در بازار رمزارز مورد نظرتان در بستری امن و سریع لذت ببرید',
  helpPageDescriptionLine2: 'روش های خرید و فروش ارز دیجیتال در ساترکس',
  helpPageDescriptionLine3: 'در صرافی دیجیتال ساترکس شما می توانید به سه روش سفارش­گذاری محدود، سفارش­گذاری بازار و سفارش­گذاری سریع در بازارهای رمزارز مختلف به مبادله بپردازید. برای اطلاعات بیشتر ادامه مطلب را در این بخش مطالعه کنید ',
  helpPageDescriptionLine4: 'نحوه ثبت نام در ساترکس را در این بخش مطالعه کنید',
  helpPageDescriptionLine5: 'نحوه احرازهویت در ساترکس را در این بخش مطالعه کنید.',
  helpPageDescriptionLine6: 'واریز وجه ریالی',
  helpPageDescriptionLine7: 'مراحل واریز وجه ریالی و محدودیت­های مربوط به هر سطح کاربری در این بخش قابل دسترسی است',
  helpPageDescriptionLine8: 'برداشت وجه ریالی',
  helpPageDescriptionLine9: 'مراحل برداشت وجه ریالی و محدودیت­های مربوط به هر سطح کاربری در این بخش قابل دسترسی است',
  helpPageDescriptionLine10: 'واریز رمزارز',
  helpPageDescriptionLine11: 'نکات مربوط به واریز رمزارز در حساب کاربری را در این بخش مطالعه کنید',
  helpPageDescriptionLine12: 'نکات مربوط به برداشت رمزارز در حساب کاربری را در این بخش مطالعه کنید',
  helpPageDescriptionLine13: 'راهنما سطوح کاربری',
  helpPageDescriptionLine14: 'سطوح کاربری مختلف، مدارک و نحوه احرازهویت ، محدودیت ها و امکانات هر سطح را از این بخش دریافت کنید',
  helpPageDescriptionLine15: 'راهنمای ارسال تصویر شخصی احراز هویت',
  helpPageDescriptionLine16: 'نحوه ارسال تصویر شخصی را در این بخش مطالعه کنید',
  helpPageDescriptionLine17: 'برای شروع به فعالیت در صرافی دیجیتال ساترکس مراحل زیر را تکمیل کنید.',
  helpPageDescriptionLine18: 'ثبت نام در سامانه ساترکس با شماره موبایل ثبت شده به نام کاربر (راهنما تصویری ثبت نام) ',
  helpPageDescriptionLine19: 'ورود به سایت و تکمیل فرآیند احراز هویت (راهنما تصویری احراز هویت)',
  helpPageDescriptionLine20: 'واریز وجه ریالی و یا رمز ارز (راهنما تصویری واریز وجه ریالی و رمزارز)',
  helpPageDescriptionLine21: 'ثبت سفارش خرید یا فروش در سیستم ساترکس (راهنما تصویری ثبت سفارش)',
  helpPageDescriptionLine22: 'برداشت وجه ریالی و یا رمز ارز (راهنما تصویری برداشت وجه ریالی و رمز ارز)',
  helpPageDescriptionLine23: 'کاربران محترم ساترکس می­توانند پس از ثبت نام، تکمیل و تایید اطلاعات پروفایل خود نسبت به شارژ حساب کاربری خود اقدام نموده و در بازار رمزارز مورد نظر، به مبادله بپردازند ',
  helpPageDescriptionLine24: 'در این نوع سفارش­گذاری، شما می­توانید رمزارز مورد نظرتان را با قیمت درخواستی در بازار ثبت کنید. متناسب با حجم و قیمت انتخابی، نوسانات بازار و تقاضا معامله ثبت شده به صورت جزئی یا کامل انجام خواهد شد. لازم به ذکر است در این نوع سفارش هیچ تضمینی برای انجام کامل معامله وجود ندارد. اطلاعات سفارشات محدود شما در جدول سفارشات باز قابل مشاهده است. چنانچه معامله به صورت کامل انجام شود، جزئیات معامله از منوی گزارشات/ سفارشات انجام شده دردسترس خواهد بود. در صورتیکه که کل یا بخشی از معامله انجام نشده باشد، می­توانید با لغو سفارش از منو سفارشات باز، سرمایه بلوکه شده را آزاد کنید',
  helpPageDescriptionLine25: 'در این نوع سفارش­گذاری شما حجم درخواستی خود را با بهترین قیمت­های ثبت شده در بازار رمزارز مذکور معامله خواهید کرد. از آنجا که الزاما حجم درخواستی شما با حجم ثبت شده در بازار یکسان نخواهد بود این نوع معامله ممکن است در چند مرحله و با قیمت­های متفاوت انجام شود. چنانچه حجم درخواستی شما بیشتر از کل سفارشات ثبت شده در بازار باشد بخش باقیمانده در سفارشات باز شما در انتظار معامله خواهد ماند. در صورت تمایل شما می­توانید باقیمانده سفارش را از منو سفارشات باز لغو کنید تا سرمایه بلوکه شده آزاد شود',
  helpPageDescriptionLine26: 'در این نوع سفارش شما حجم درخواستی رمزارز را با قیمت تعیین شده از طرف سیستم معامله خواهید کرد. معاملات سریع به طور قطع انجام خواهند شد، مگر اینکه نوسانات شدید بازار از لحظه نمایش قیمت در فیلد مربوطه تا ثبت سفارش رخ دهد. در این حالت سیستم سفارش شما را لغو کرده، سرمایه بلوکه شده بطور خودکار آزاد شده و پیامک لغو سفارش به شما ارسال می­شود',
  helpPageDescriptionLine27: 'برای ثبت نام ابتدا به آدرس وبسایت ساترکس Https://www.satrex.ir بروید، سپس در نوار بالا سمت چپ بر روی گزینه ثبت نام کلیک کنید ',
  helpPageDescriptionLine28: 'فرم ثبت نام برای شما نمایش داده می­شود.لطفا اطلاعات خواسته شده شامل شماره موبایل،رمز عبور و تکرار رمز عبور را به دقت وارد کنید و بر روی دکمه ثبت نام کلیک کنید. توجه داشته باشید شماره موبایل وارد شده باید به نام کاربر باشد ',
  helpPageDescriptionLine29: 'در این مرحله کد تایید ارسال شده به شماره موبایل را وارد کرده و بر روی دکمه ادامه کلیک کنید',
  helpPageDescriptionLine30: 'به محض ارسال صحیح کد، به پنل کاربری خود وارد می­شوید.',
  helpPageDescriptionLine31: 'پس از ثبت نام در ساترکس به منظور استفاده از امکانات سامانه مراحل احراز هویت کاربر باید انجام شده و توسط مدیر سیستم تایید شود. مدارک مورد نیاز جهت احراز هویت با توجه به سطوح مختلف کاربری به صورت زیر می­باشد:',
  helpPageDescriptionLine32: 'از منو پروفایل، ویرایش اطلاعات شخصی را انتخاب و فرم اطلاعات هویتی را تکمیل نمایید. در این مرحله شما به یک آدرس ایمیل معتبر نیاز دارید. بعد از وارد کردن آدرس ایمیل بر روی گزینه ارسال کد کلیک کرده و با وارد کردن کد ارسالی، مرحله تایید ایمیلتان را کامل کنید.',
  helpPageDescriptionLine33: 'در مرحله بعد از منو پروفایل، ویرایش اطلاعات بانکی را انتخاب کنید برروی افزودن حساب بانکی کلیک کنید. شماره کارت (جهت واریز وجه ریالی) و شماره شبا (جهت برداشت وجه ریالی) را در کادر وارد کنید، سپس بر روی دکمه ذخیره کلیک کنید. توجه داشته باشید کارت بانکی باید متعلق به کاربر بوده و از ثبت کارت بانکی اشخاص دیگر خودداری فرمایید',
  helpPageDescriptionLine34: 'پس از تایید اطلاعات سطح کاربری 1 برای شما ثبت خواهد شد. برای اطلاع از سطوح کاربری مختلف به صفحه سطوح کاربری مراجعه کنید.',
  helpPageDescriptionLine35: 'فرم اطلاعات تماس و ارسال تصویر کارت ملی و تصویر شخصی مطابق نمونه از منو ویرایش اطلاعات شخصی را تکمیل نمایید. پس از بررسی و تایید توسط مدیر سیستم، سطح کاربری شما به سطح 2 ارتقا خواهد یافت.',
  helpPageDescriptionLine36: 'پس از تایید فرآیند احراز هویت برای معامله در ساترکس، حساب کاربری خود را از طریق واریز وجه یا رمزارز می­توانید شارژ کنید.',
  helpPageDescriptionLine37: 'برای واریز وجه به صورت زیر عمل کنید:',
  helpPageDescriptionLine38: '۱.از منو حسابداری گزینه واریز وجه را انتخاب کنید.',
  helpPageDescriptionLine39: '۲.یکی از کارت­های بانکی که در سیستم ثبت و تایید شده را انتخاب کنید. مبلغ مورد نظر خود را در کادر مشخص شده، به تومان وارد کنید. سپس بر روی انتقال به درگاه بانکی کلیک کنید.',
  helpPageDescriptionLine40: '۳.اطلاعات درگاه بانکی را تکمیل کنید. توجه داشته باشید از کارت بانکی که در سیستم ثبت و تایید شده است استفاده کنید.',
  helpPageDescriptionLine41: 'برای برداشت وجه به صورت زیر عمل کنید:',
  helpPageDescriptionLine42: '۱.از منو حسابداری گزینه برداشت وجه را انتخاب کنید.',
  helpPageDescriptionLine43: '۲.زمان برداشت و مبلغ مورد نظر خود را به تومان وارد کرده و شماره شبا یکی از حساب­های بانکی ثبت و تایید شده در سیستم را انتخاب کنید. سپس با درخواست ارسال کدپیامکی و وارد کردن آن در کادر مربوطه درخواست خود را ثبت کنید.',
  helpPageDescriptionLine44: '۳.پس از تایید توسط کارشناس مربوطه وجه درخواستی به حساب شما واریز و پیامک تایید به شما ارسال خواهد شد. وضعیت درخواست خود را از صفحه درخواست ها می توانید پیگیری نمایید.',
  helpPageDescriptionLine45: 'شما می­توانید با ایجاد آدرس کیف پول در ساترکس، رمزارزهای خود را به راحتی منتقل کرده و اقدام به معامله و یا برداشت کنید ',
  helpPageDescriptionLine46: '۱.از منو حسابداری، واریز و برداشت رمزارز را انتخاب کنید',
  helpPageDescriptionLine47: '۲.از قسمت واریز، رمزارز مورد نظر را انتخاب کرده و بر روی دکمه نمایش آدرس کلیک کنید. آدرس ایجاد شده در ساترکس مختص هر کاربر بوده و به صورت دایمی است.',
  helpPageDescriptionLine48: '۳.آدرس کیف پول تولید شده در کادر را کپی و یا QR کد را اسکن کنید.',
  helpPageDescriptionLine49: '۴.آدرس کپی شده را درکیف پولی که قصد واریز از آن را دارید وارد کرده و یا در اختیار شخص فرستنده قرار دهید . ',
  helpPageDescriptionLine50: '۵.پس از واریز منتظر تایید شبکه بلاکچین باشید تا کیف پول ساترکس شما شارژ شود.',
  helpPageDescriptionLine51: '۶.پس از واریز منتظر تایید شبکه بلاکچین باشید تا کیف پول ساترکس شما شارژ شود.',
  helpPageDescriptionLine52: '۱.ابتدا از منو حسابداری وارد صفحه واریز و برداشت رمزارز شوید',
  helpPageDescriptionLine53: '۲.از قسمت برداشت رمزارز، رمزارز مورد نظر را انتخاب کرده ، مقدار برداشت و آدرس کیف پول مقصد را وارد کنید. سپس با کلیک بر روی دکمه درخواست کد پیامکی، کد تایید ارسال شده به شماره همراهتان را وارد کرده و درخواست خود را ثبت کنید.',
  helpPageDescriptionLine54: '۳.منتظر تایید کارشناس مربوطه بمانید. وضعیت درخواست خود را از صفحه درخواست ها می توانید پیگیری نمایید.',
  helpPageDescriptionLine55: 'چندین سطح کاربری مختلف در ساترکس در نظر گرفته شده است تا کاربران با توجه به نیازهای خود از مزایای هر سطح بهره­مند شوند. ',
  helpPageDescriptionLine56: 'کاربرانی که بعد از ثبت نام در ساترکس اقدام به تکمیل فرم اطلاعات شخصی و اطلاعات بانکی کرده و صحت اطلاعات آنها از طرف سیستم تایید شده است در سطح 1 کاربری قرار می­گیرند. لازم به ذکر است در این مرحله وارد کردن شماره کارت بانکی و شماره شبا به نام کاربر الزامی است.',
  helpPageDescriptionLine57: 'کاربرانی که علاوه بر اطلاعات شخصی و بانکی، فرم اطلاعات تماس خود را کامل کرده و تصویر کارت ملی و تصویرشخصی مطابق نمونه را در سیستم بارگذاری کرده اند و صحت اطلاعات آنها توسط سیستم تایید شده است در سطح 2 کاربری قرار خواهند گرفت. از مزایای این سطح افزایش سقف واریز و برداشت ها به شرح زیر می­باشد:',
  helpPageDescriptionLine58: 'واریز رمزارز به صورت نامحدود و واریز وجه ریالی مطابق با محدودیت­های بانک مرکزی خواهد بود ( در حال حاضر 50 میلیون از هر کارت و مجموع کارتهای بانکی تا سقف 100 میلیون در روز). حداکثر میزان درخواست برداشت برای کاربران سطح 2، معادل 500 میلیون تومان در 24 ساعت اخیر می­باشد (300 میلیون تومان برداشت وجه ریالی و معادل 200 میلیون تومان رمزارز). حداکثر میزان درخواست برداشت در 30 روز گذشته معادل 3 میلیارد تومان خواهد بود',
  helpPageDescriptionLine59: 'فرآیند احراز هویت برای کاربران سطح 2 با ارسال تصویر شخصی مطابق با نمونه کامل خواهد شد.برای این منظور کارت ملی، کارت بانکی و فرم تعهدنامه را مطابق نمونه در دست گرفته و تصویر واضحی از خود تهیه نمایید.',
  helpPageDescriptionLine60: 'در تهیه عکس شخصی به نکات زیر توجه داشته باشید:',
  helpPageDescriptionLine61: '1. کارت ملی را به گونه ای در دست بگیرید که اطلاعات آن قابل رویت باشد. چنانچه به هر دلیلی دسترسی به کارت ملی ندارید، از شناسنامه جدید یا پاسپورت معتبر استفاده کنید.',
  helpPageDescriptionLine62: '2. یکی از کارت­های بانکی که در سیستم ثبت نموده­اید را در دست گرفته و یا بر روی کاغذ تعهدنامه بچسبانید. می­توانید اطلاعات تاریخ انقضا و CCV2 را بپوشانید ولی شماره کارت و نام صاحب کارت مخدوش نشود.',
  helpPageDescriptionLine63: '3. متن تعهدنامه زیر را به صورت دست نویس روی کاغذ نوشته و امضا کنید.',
  helpPageDescriptionLine64: '4. توجه داشته باشید هنگام تهیه تصویر، متن دست نوشته و اطلاعات کارت ملی به صورت برعکس و آینه ای ثبت نشود.',
  helpPageDescriptionLine65: 'اینجانب (نام و نام خانوادگی) به کد ملی 000000000000 قوانین وشرایط استفاده از ساترکس را مطالعه و متعهد میگردم که حساب کاربری خود را در اختیار غیر قرار ندهم. در صورت هرگونه تخلف، مسئولیت آن اعم از مالی، قضایی و حقوقی را برعهده می­گیرم',
  helpPageDescriptionLine66: 'نام و نام خانوادگی، تاریخ و امضا',
  helpPageDescriptionLine67: 'آیا این مطلب برای شما مفید بود؟',
  '1.LimitedOrdering': '1. سفارش­گذاری محدود',
  'Limited ordering': 'سفارش­گذاری محدود',
  '2.MarketOrdering': '2. سفارش­گذاری بازار',
  'Market ordering': 'سفارش­گذاری بازار',
  FastOrdering: 'سفارش­گذاری سریع ',
  UserLevel1: 'سطح کاربری 1',
  UserLevel2: 'سطح کاربری 2',
  totalTradable: 'موجودی قابل معامله',
  favoritMarkets: 'رمز ارز های انتخابی',
  noVarifiedBankFound: 'No verified bank account found!',
  '100millionCryptocurrencyPerDay': '100 میلیون تومان در روز و واریز رمزارز نامحدود',
  '300MillionTomansPerday': '۳۰۰ میلیون تومان در روز',
  '500MillionTomansPerday': '۵۰۰ میلیون تومان در روز',
  MaximumLevelTwoApplicationsIn30days: 'سقف مجموع درخواست های سطح دو در 30 روز',
  '3BillionTomans': '۳میلیارد تومن',
  ApprovedBySystem: 'تایید شده توسط سیستم',
  youAreInAuthPhase: 'شما در مرحله ی احراز هویت هستید.',
  profileLine1: ' کاربر محترم ساترکس، چنانچه تمایل به شروع فعالیت مالی در سایت ساترکس را دارید پس از مطالعه توضیحات فرآیند احراز هویت خود را آغاز نمایید.',
  profileLine2: ' احراز هویت پایه‌ای با تایید شماره تلفن همراه، ایمیل، ارسال مدارک هویتی (کارت ملی)، ورود آدرس محل سکونت، تلفن ثابت و تایید آن‌ها، و ورود اطلاعات حساب و کارت بانکی صورت می‌گیرد. پس از تایید اطلاعات شما قادر به واریز وجه/کوین، معامله و برداشت وجه/کوین خواهید بود.',
  profileLine3: ' لازم به ذکر است طبق قوانین بانکی کشور پس از ثبت هرکارت بانکی جدید، به مدت یک روز کاری امکان ثبت درخواست برداشت وجه از آن کارت را نخواهند داشت. این تاخیر صرفاً جهت تایید اصالت کارت بانکی می­باشد و در طول این مدت کاربران می‌توانند به هر میزان با وجه واریزی خود در سیستم معامله نمایند و آن را تبدیل به سایر ارزهای دیجیتال کنند و صرفا محدودیت در خارج کردن وجه واریزی به مدت یک روز کاری از سیستم ساترکس است.',
  PreviousStep: 'مرحله ی قبل',
  NextStep: 'مرحله ی بعد',
  FirstLevel: 'سطح اول',
  SecondLevel: 'سطح دوم',
  ReviewRequest: 'درخواست بررسی',
  details: 'جزئیات',
  UserDeposit: 'واریزی کاربر',
  rank: 'رتبه بازار',
  LackAccessSection: 'عدم دسترسی به این بخش',
  suspendText: ' متاسفانه شما به این بخش دسترسی ندارید لطفا جهت افزایش سطح کاربری خود اقدام نمایید',
  marketAddToFav: 'مارکت به لیست علاقه مندی های شما اضافه شد',
  failedInMission: 'عملیات با خطا مواجه شد لطفا مجدد تلاش کنید',
  marketRemovedFromFav: 'مارکت از لیست علاقه مندی های شما حذف شد',
  codeWasSent: 'کد به تلفن همراه شما ارسال شد',
  selectCryptoRequired: 'انتخاب یک رمزارز الزامی است',
  'WithdrawalMustBe>0': 'مقدار برداشت باید بزرگتر از صفر باشد',
  EnterDestinationWalletAddress: 'وارد کردن آدرس کیف پول مقصد الزامی است',
  toFactorCodeRequired: 'وارد کردن کد شناسایی دوعاملی الزامی است',
  WithdrawalRequestRegisteredSuccessfully: 'درخواست برداشت با موفقیت ثبت شد',
  TransferToPort: 'Transfer to port',
  YouRedirectedGateway: 'شما درحال انتقال به درگاه هستید',
  SaveAndSubmitForAdminApproval: 'ذخیره و ارسال برای تایید ادمین',
  delete: 'حذف',
  MessageDetails: 'جزئیات پیام',
  ReplySentSuccessfully: 'پاسخ با موفقیت ارسال شد',
  MessageText: 'متن پیام',
  answerText: 'متن پاسخ',
  persianRequired: 'این فیلد نمیتواند مقداری غیر از فارسی باشد',
  'e-mail': 'پست الکترونیک',
  discussionTopic: 'موضوع بحث',
  'valueMustBe>0': 'مقدار باید بیشتر از صفر باشد',
  'priceMustBe>0': 'قیمت باید بیشتر از صفر باشد',
  LackOfSufficientInventory: 'عدم داشتن موجودی کافی',
  orderSuccessfullyRegistered: 'سفارش با موفقیت ثبت شد',
  maxSell: 'حداکثر مقدار فروش شما',
  cryptoNotFoundInWallet: 'ارزی در کیف پول های شما یافت نشد!',
  welcome: 'خوش آمدید',
  note1InWalletPage: 'موجودی/ تومان رمزارز شما براساس آخرین معامله انجام شده برای هر رمزارز در تالار محاسبه می شود',
  note: 'نکته :',
  'HowDoesSatrexWork?(HowDoesSatrexWork?)': 'ساترکس چگونه کار میکند؟(نحوه کار ساترکس چگونه است؟)',
  DescriptionsToBeCompletedByPeopleFamiliarWithTheSystem: 'توضیحات توسط افراد آشنا به سیستم تکمیل گردد',
  'WhyIsSatrexTrusted?': 'چرا ساترکس مورد اعتماد است؟',
  'HowToRegisterInSatrex?': 'چگونه می توان در ساترکس ثبت نام کرد؟',
  transferMoneyDate: 'با توجه به قوانین بانکی و محدودیت های انتقال پول(ریال)، حداکثر انتقال وجه به حساب بانکی شما 72 ساعت می باشد.',
  answerOfregistration: 'پس از ورود به سایت، وارد بخش ثبت نام سایت شده و با وارد کردن شماره همراه، ثبت نام اولیه را انجام دهید.',
  faqPageDescriptionLine1: 'با توجه به پیشرفت و گسترش بازار سرمایه گذاری ارز دیجیتال، گروهی از بهترین متخصصان برنامه نویسی و تحلیل گر جهت فراهم کردن بستری امن و مطمئن با بالاترین سطح امنیتی روز دنیا جهت معامله بازار سرمایه ارز دیجیتال زیر نظر قوانین جمهوری اسلامی ایران شروع به فعالیت کردند.',
  faqPageDescriptionLine2: 'چگونه می توان در ساترکس شروع به معامله کرد؟',
  faqPageDescriptionLine3: 'بعد از انجام ثبت نام اولیه و ورود به سایت، وارد قسمت پروفایل شده و در آنجا اطلاعات شخصی و اطلاعات بانکی خود را تکمیل نمایید.در ادامه شما می بایس هویت خود را احراز نمایید. پس از انجام مراحل احراز هویت و تایید توسط تیم پشتیبانی شما قادر به معامله در بازار رمز ارز خواهید شد.',
  faqPageDescriptionLine4: 'جهت احراز هویت چه مدارکی لازم است و چه باید کرد؟',
  faqPageDescriptionLine5: 'جهت احراز هویت لارم است تصویر کار ملی خود و نیز یک عکس سلفی از خود در کنار کارت ملی را در قسمت پروفایل بارگزاری نمایید. هم چنین لازم است اطلاعات شخصی و نیز اطلاعات بانکی به نام خود را در قسمت پروفایل به صورت دقیق تکمیل نمایید.',
  faqPageDescriptionLine6: 'تایید اطلاعات جهت احراز هویت چه مدت به طول می انجامد؟',
  faqPageDescriptionLine7: 'تیم ساترکس جهت تایید اطلاعات هویتی شما عزیزان حداکثر ظرف 24 ساعت اقدام خواهد کرد.',
  faqPageDescriptionLine8: 'چطور پول یا ارز دیجیتال وارد حساب ساترکس خود کنم؟',
  faqPageDescriptionLine9: 'جهت واریز ریال می توان در بخش حسابداری قسمت واریز وجه، با یکی از کارت های ثبت شده در حسابتان عملیات واریز وجه را انجام دهید.',
  faqPageDescriptionLine10: 'هم چنین جهت واریز رمز ارز می توان در بخش حسابداری قسمت واریز وجه، با ایجاد آدرس کیف پول اختصاصی رمز ارز مورد نظر جهت انتقال ارز به صرافی ساترکس اقدام کرد.',
  faqPageDescriptionLine11: 'کارمزد های خرید و فروش و برداشت در ساترکس چگونه است؟',
  faqPageDescriptionLine12: 'دلیل احراز هویت چیست و چرا باید احراز هویت شویم؟',
  faqPageDescriptionLine13: 'طبق آخرین قوانین جهت معاملات ارز دیجیتال، جهت جلوگیری و مبارزه با پول شویی، هر فرد جهت معامله موظف است ابتدا هویت خود را احراز نماید تا در صورت تخلف بتوان پیگیری های لازم انجام گیرد',
  faqPageDescriptionLine14: 'چگونه ارز دیجیتال در ساترکس خرید و فروش کنیم؟',
  faqPageDescriptionLine15: 'با ثبت نام در سایت و انجام عملیات احراز هویت و تایید توسط سایت، شخص می تواند با واریز وجه یا واریز ارز دیجیتال به کیف پول خود، در سایت ساترکس معامله خود را آغاز نماید',
  faqPageDescriptionLine16: 'تسویه حساب تراکنش ها چه مدت به طول می انجامد؟',
  faqPageDescriptionLine17: 'جهت پیگیری تراکنش هایی که با مشکل مواجه شده اند , چه باید کرد؟',
  faqPageDescriptionLine18: 'مشکل خود را با کارشناسان پشتیبانی در میان بگذارید تا در اولین فرصت مشکل شما پیگیری و رفع گردد.',
  faqPageDescriptionLine19: 'ساعت کاری پشتیبانی ساترکس به چه صورت است؟',
  faqPageDescriptionLine20: 'درصورتی که پاسخ سوال خود را نیافتید می توانید از طریق یکی از راه های ذکر شده با پشتیبانان ما ارتباط برقرار نماید.',
  levelguidePageDescriptionLine1: 'ساترکس بر اساس مراحل احراز هویت، برای کاربران خود سطوح کاربری مختلف را تعریف می­کند. در صرافی دیجیتال ساترکس شما در محیطی امن و سالم با کاربرانی که به طور سیستماتیک هویتشان تایید شده است با کمترین ریسک ممکن امکان معامله خواهید داشت.',
  levelguidePageDescriptionLine2: 'در سیستم احراز هویت ساترکس اطلاعات شناسنامه ای، شماره تلفن، اطلاعات حساب بانکی و اطلاعات محل سکونت کاربران توسط نهادهای مربوطه به طور خودکار بررسی و تایید خواهند­ شد. از مزایای این روش احراز هویت می­توان به موارد زیر اشاره کرد:',
  levelguidePageDescriptionLine3: 'ایجاد فضای امن معاملاتی به واسطه حضور کاربرانی که تایید هویت شده­اند',
  levelguidePageDescriptionLine4: 'فرآیند ساده و سریع جهت احراز هویت',
  UserLevelOne: 'سطح کاربری یک',
  UserLevelTwo: 'سطح کاربری دو',
  CompanyAccount: 'حساب شرکتی',
  TradingFee: 'کارمزد معاملات',
  'Rial withdrawal rate': ' میزان برداشت ریالی',
  'Trading volume of the last 30 days': 'حجم معاملات 30 روز گذشته',
  FeesAndServiceFees: 'کارمزدها و هزینه خدمات',
  'Less than 10 million tomans': 'کمتر از 10 میلیون تومان',
  'Between 50 and 100 million Tomans': 'بین 50 تا 100 میلیون تومان',
  'More than 100 million tomans': 'بیشتر از 100 میلیون تومان',
  'Between 10 and 50 million Tomans': 'بین 10 تا 50 میلیون تومان',
  levelguidePageDescriptionLine5: 'شروع به فعالیت راحت و آسان برای کاربران سطح پایین',
  levelguidePageDescriptionLine6: 'ارائه خدمات حرفه ای تر و مزایای افزایش سطح واریز و برداشت بیشتر برای کاربران در سطوح بالا',
  levelguidePageDescriptionLine7: 'با تایید شماره موبایل به نام کاربر، ایمیل، کد ملی و اطلاعات حساب بانکی شما در این سطح کاربری قرار می‌گیرید. امکان واریز رمزارز به‌صورت نامحدود و همچنین امکان مبادله در بازارهای رمزارز به رمزارز و رمزارز به ریال به‌صورت نامحدود برای کاربران این سطح وجود خواهد داشت. سقف واریز ریالی برای کاربران این سطح 500 هزار تومان در 24 ساعت اخیر است. سقف برداشت رمزارز در 24 ساعت اخیر معادل 100 میلیون تومان و سقف برداشت ریال معادل 20 میلیون تومان می‌باشد. سقف مجموع درخواست‌های برداشت برای کاربران این سطح در 30 روز اخیر، 1.5 میلیارد تومان است ',
  levelguidePageDescriptionLine8: 'برای ارتقا به سطح دو کاربری، اطلاعات تماس، تصویر کارت ملی و عکس سلفی مطابق نمونه باید در سیستم مورد تایید واقع شود. امکان واریز رمزارز به‌صورت نامحدود و امکان واریز ریالی از طریق درگاه شتابی طبق محدودیت بانک مرکزی از هر کارت بانکی روزانه تا 50 میلیون تومان و مجموع کارتهای بانکی تا سقف 100 میلیون تومان در روز است. همچنین امکان مبادله در بازارهای رمزارز به رمزارز و رمزارز به ریال به‌صورت نامحدود برای کاربران این سطح وجود خواهد داشت. سقف برداشت رمزارز و ریال در مجموع در 24 ساعت اخیر معادل 500 میلیون تومان (300 میلیون تومان سقف برداشت ریالی و 200 میلیون تومان سقف برداشت رمزارز) می باشد. سقف مجموع درخواست‌ها برای کاربران این سطح در 30 روز اخیر، 3 میلیارد تومان است.',
  levelguidePageDescriptionLine9: 'برای ایجاد حساب شرکتی در ساترکس فرم تعهدنامه به صورت تایپی توسط مدیرعامل و یا نماینده شرکت تکمیل و بر روی سربرگ شرکت چاپ شود. لازم به ذکر است در مرحله اول مدیرعامل و یا نماینده شرکت حتما حساب کاربری حقیقی خود را فعال نماید سپس مدارک لازم شامل آگهی ثبتی آخرین تغییرات شرکت؛ گواهی کد اقتصادی و فرم تعهدنامه در قالب یک فایل zip به آدرس ایمیل info@satrex.ir با موضوع "حساب شرکتی" ارسال شود. محدودیت حساب شرکتی مانند سطح دو بوده و امکان ارتقا سطح وجود دارد.',
  feeguidePageDescriptionLine1: 'ساترکس با هدف ایجاد بستری شفاف، امن و دقیق برای کاربران خود، هزینه و کارمزد تمامی اعمال مالی اعم از واریز، برداشت و معاملات را به اطلاع کاربران خود می رساند',
  feeguidePageDescriptionLine2: 'کارمزد معاملات در ساترکس بر اساس شرایط و ضوابط زیر محاسبه می شود:',
  feeguidePageDescriptionLine3: 'کارمزد از هر دو طرف معامله، خریدار و فروشنده، کسر می‌شود',
  feeguidePageDescriptionLine4: 'در ساترکس کارمزد معاملات بصورت درصد از مبلغ کل معامله است که بر اساس حجم معاملات کاربر در 30 روز گذشته، مطابق جدول زیر محاسبه می­شود. لازم به ذکر است کارمزد هر سمت معامله براساس دارایی دریافتی کسر خواهد شد. به عنوان مثال کارمزد خریدار ارزدیجیتال به صورت درصدی از ارزدیجیتال دریافتی و کارمزد فروشنده ارز دیجیتال، از مبلغ دریافتی به تومان کسر خواهد شد.',
  feeguidePageDescriptionLine5: 'با ثبت درخواست معامله در ساترکس، مبلغ کارمزد آن معامله نمایش داده خواهد شد.',
  feeguidePageDescriptionLine6: 'جدول کارمزد معاملات در ساترکس که با افزایش میزان حجم معاملات ماهیانه، به صورت پله‌ای کاهش می­یابد:',
  feeguidePageDescriptionLine7: 'معاملات انجام شده در بازارهای جفت ارز ( بازارهای با مقصد تتر مانند بیت‌کوین/تتر ) ، مستقل از حجم به صورت ویژه با کارمزد % 13/0 برای makerو %15/0برای taker محاسبه می‌شود. ',
  feeguidePageDescriptionLine8: 'کارمزد برداشت ارز دیجیتال',
  feeguidePageDescriptionLine9: 'کارمزد برداشت ارزهای دیجیتال به نوع ارز بستگی دارد. این هزینه در واقع به دلیل کارمزدهای تراکنش شبکه کسر می‌شود و ساترکس در تعیین آن نقشی ندارد. در حال حاضر این هزینه‌ها به شرح زیر است',
  feeguidePageDescriptionLine10: 'کارمزد واریز و برداشت ریالی',
  'Withdrawal fee': 'کارمزد برداشت',
  'Less than 400 thousand tomans': 'کمتر از 400 هزار تومان',
  'One percent of withdrawal': 'یک درصد میزان برداشت',
  '400 thousand tomans to 50 million tomans': '400هزار تومان تا 50 میلیون تومان',
  feeguidePageDescriptionLine11: 'کارمزد واریز ریالی صفر است.',
  'More than 50 million tomans': 'بیشتر از 50 میلیون تومان',
  '4000 Tomans for every 50 million Tomans': '4000 تومان به ازای هر 50 میلیون تومان',
  feeguidePageDescriptionLine12: 'کارمزد برداشت ریالی شامل هزینه های بانکی می باشد و ساترکس سهمی از آن نخواهد داشت. طبق قوانین بانکی کارمزد برداشت ریالی مطابق جدول زیر خواهد بود',
  feeguidePageDescriptionLine13: 'باتوجه به سیکل‌های تسویه پایا در کشور بنا بر ابلاغیه بانک مرکزی، زمان‌های تسویه ریالی ساترکس به شرح زیر است:',
  feeguidePageDescriptionLine14: 'درخواست‌های برداشت ریال صرفاً در روزهای کاری پردازش می‌شوند و در روزهای تعطیل به دلیل از سرویس خارج بودن شبکه‌های ساتنا و پایا امکان تسویه به جز در موارد خاص وجود ندارد.',
  feeguidePageDescriptionLine15: 'درخواست‌های ثبت شده تا ساعت 12:30، در سیکل 13:45 توسط بانک مرکزی پردازش و معمولاً با نیم تا یک ساعت تاخیر به حساب کاربران محترم واریز خواهد شد.',
  feeguidePageDescriptionLine16: 'درخواست‌های ثبت شده بعد از ساعت 12:30 تا ساعت 22، در روز کاری بعد در ساعت 4 الی 7 صبح به حساب کاربران محترم واریز خواهد شد',
  feeguidePageDescriptionLine17: 'درخواست‌های ثبت شده بعد از ساعت 22 تا 9 صبح، در ساعت 10:45 دقیقه صبح توسط بانک مرکزی پردازش و معمولاً با نیم تا یک ساعت تاخیر به حساب کاربران محترم واریز خواهد شد.',
  aboutusPageDescriptionLine1: 'با توجه به گسترش و پیشرفت دنیای معاملاتی ارز دیجیتال و بیشتر شدن علاقه معامله گران به بازارهای ارزهای دیجیتال و هم چنین تحریم همه جانبه کاربران محترم ایرانی توسط صرافی های بین المللی، گروه ساترکس که متشکل از بهترین و با تجربه ترین برنامه نویسان، متخصصان امنیت شبکه و تحلیلگران می باشد جهت راحت کردن معامله شما هم وطنان عزیز بستری امن و قابل اعتماد را ایجاد نمودند تا مردم شریف ایران بتوانند با خیالی راحت و با حفظ سرمایه های خود در بستری امن، سرعتی بالا و رابط کاربری آسان شروع به معامله در بازار سرمایه ارزهای دیجیتال نمایند.',
  aboutusPageDescriptionLine2: 'هدف ساترکس',
  aboutusPageDescriptionLine3: 'ایجاد یالاترین سطح امنیتی حال حاضر دنیا جهت حفظ سرمایه های کاربران محترم و هم چنین ایجاد رابط کاربری بسیار آسان جهت استفاده همه افراد فعال در این بازار به همراه سرعت انجام بالای معاملات می باشد.',
  aboutusPageDescriptionLine4: 'از مزیت های ساترکس می توان به:',
  aboutusPageDescriptionLine5: 'امنیت دارایی های کاربران محترم',
  aboutusPageDescriptionLine6: 'معامله برترین و معتبر ترین ارزهای دیجیتال',
  aboutusPageDescriptionLine7: 'تبدیل ارزهای مختلف به یک دیگر',
  aboutusPageDescriptionLine8: 'حفظ دارایی کاربران از تحریم های بین المللی',
  aboutusPageDescriptionLine9: 'دسترسی آسان و سریع به بازار معامله از طریق وب سایت و اپلیکیشن',
  aboutusPageDescriptionLine10: 'پشتیبانی 24 ساعته توسط بهترین و باتجربه ترین افراد',
  'Cited.': 'اشاره کرد.',
  policiesPageDescriptionLine1: 'قوانین و شرایط استفاده از خدمات ساترکس',
  policiesPageDescriptionLine2: 'شرکت ساترکس بستری برای تبادل دارایی های دیجیتال با پول رایج کشور (تومان) می باشد. این شرکت تابع قوانین جمهوری اسلامی ایران بوده و هیچ گونه تبادل ارزی اعم از خرید و فروش دلار یا سایر ارزهای کاغذی، در این بستر صورت نمی گیرد. ',
  policiesPageDescriptionLine3: 'تعهدنامه فعالیت در بستر تبادل دارایی‌های دیجیتال ساترکس',
  policiesPageDescriptionLine4: 'کاربران محترم موظفند قبل از ثبت‌نام در سایت تمامی بندهای تعهدنامه را مطالعه کرده و در صورت پذیرش آن اقدام به ثبت نام در سایت نمایند.',
  policiesPageDescriptionLine5: 'کلیه فعالیت کاربران سایت می‌پذیرند که فعالیت‌های آن‌ها در سایت ساترکس در چارچوب قوانین جمهوری اسلامی بوده و فعالیت آن ها خارج از قوانین جمهوری اسلامی نخواهد بود.',
  policiesPageDescriptionLine6: 'حداقل سن برای ثبت نام، احراز هویت و فعالیت در ساترکس 18 سال می باشد و عملیات احراز هویت برای کاربران زیر سن قانونی انجام نخواهد گرفت.',
  policiesPageDescriptionLine7: 'برای استفاده از خدمات ساترکس، کاربر در ابتدا باید احراز هویت نماید. کاربران موظفند اطلاعات صحیح خود را جهت احراز هویت بارگزاری نمایند. در صورت هرگونه مغایرت در اطلاعات بارگزاری شده جهت احراز هویت، بدیهی است مسئولیت به عهده ی فرد متخلف بوده و ساترکس حق توقف ارائه خدمات به کاربر و ارجاع موارد تخلف به مراجع قانونی را خواهد داشت. هم چنین ساترکس خود را ملزم به حفظ اطلاعات شخصی کاربران از سایرین می‌داند',
  policiesPageDescriptionLine8: 'کاربران محترم ساترکس تعهد می دهند که از ریسک های موجود در بازار سرمایه گذاری ارز های دیجیتال اطلاعات کافی دارند و با آگاهی به این موضوع اقدام به سرمایه گذاری می نمایند.',
  policiesPageDescriptionLine9: 'ساترکس هیچ گونه مسئولیتی در قبال معاملات و سود و زیان کاربران محترم در بازار سرمایه گذاری ندارد.',
  policiesPageDescriptionLine10: 'ساترکس هیچ مسئولیتی در قبال سهل انگاری کاربران در حفظ اطلاعات امنیتی از جمله گذرواژه را ندارد و تمامی مسئولیت آن به عهده کاربر خواهد بود. (پیشنهاد میگردد جهت حفظ امنیت بیشتر از کد شناسایی دو عاملی استفاده گردد.)',
  policiesPageDescriptionLine11: 'ساترکس به کاربران خود این اطمینان را می دهد تا دارایی آن ها را با بالاترین امنیت حفظ نماید و در صورت بروز هر گونه مشکل امنیتی، خسارات کاربران را جبران نماید.',
  policiesPageDescriptionLine12: 'در صورت تمایل برای جابجایی ارز های دیجیتال، مسئولیت ارائه آدرس صحیح کیف پول بر عهده کاربر می باشد و در صورت هرگونه مشکل در انتقال ارز دیجیتال از قبیل اشتباه وارد کردن آدرس کیف پول مقصد، بلوکه شدن دارایی کاربران در کیف پول مقصد و ... بر عهده کاربر بوده و ساترکس مسئولیتی در این خصوص ندارد',
  policiesPageDescriptionLine13: ' ساترکس در مقابل واریز توکن یا کوین بر بستر اشتباه یا کوین هایی که در ساترکس پشتیبانی نمی شود هیچ گونه مسئولیتی نداشته و با توجه به مکانیسم ذخیره سازی سرد امکان استخراج این توکن ها با استفاده از ولت ثالث وجود ندارد. لذا مسئولیت هرگونه واریز اشتباه با خود کاربر بوده و کاربر حق هیچ گونه شکایتی را از ساترکس نخواهد داشت. • درخواست برداشت ریال از سایت در سریع ترین زمان ممکن بررسی و ثبت میگردد و نهایتا تا 72 ساعت به حساب کاربر واریز می گردد',
  policiesPageDescriptionLine14: 'کاربر می‌پذیرد که به جز در موارد مورد تعهد ساترکس ، حق هیچ گونه داعیه، طلب و شکایت از سایت ساترکس ، مدیران، کارمندان و افراد مرتبط با این سایت را نخواهد داشت.',
  policiesPageDescriptionLine15: 'اگر ساترکس تحت هر عنوان اشتباهاً یا من غیر حق، وجوه یا رمزارزی را به حساب کاربر منظور یا در محاسبات خود هر نوع اشتباهی نماید، هر زمان مجاز و مختار است راساً و مستقلاً و بدون انجام هیچ گونه تشریفات اداری و قضائی و دریافت اجازه کتبی از متعهد (صاحب حساب) در رفع اشتباه و برداشت از حساب‌های وی اقدام نماید و تشخیص ساترکس نسبت به وقوع اشتباه یا پرداخت بدون حق و لزوم برداشت از حساب معتبر خواهد بود و کاربر حق هرگونه اعتراض و ادعایی را در خصوص نحوه عملکرد ساترکس از هر جهت از خود ساقط می نماید.',
  policiesPageDescriptionLine16: 'در صورت بروز هرگونه مشکل یا ابهام در هر یک از معاملات، ساترکس حق دارد مستقیما آن معامله را ابطال و دارایی‌های هر یک از طرفین را به حساب خودشان عودت دهد. بدیهی است که در صورتی که اشکال از سمت ساترکس باشد، موظف خواهد بود که جبران خسارت نماید و در غیراین صورت کاربر حق هرگونه اعتراض و ادعایی را در خصوص نحوه عملکرد ساترکس از هر جهت از خود ساقط می‌نماید',
  policiesPageDescriptionLine17: 'ساترکس می تواند قوانین و مقررات مربوطه را در هر زمان بسته به شرایط و قوانین حاکم، تغییر دهد و کاربر همواره موظف است از اخرین تغییرات قوانین، آگاهی کامل را داشته باشد.',
  policiesPageDescriptionLine18: 'فرآیند تایید هویت',
  policiesPageDescriptionLine19: 'به منظور پیروی از قوانین ضد پول‌شویی، تمامی کاربران ملزم به تایید هویت توسط ساترکس جهت جلوگیری از تخلف می باشند. تایید هویت در ...... مرحله با ارائه مدارک مربوطه صورت می‌گیرد.',
  supportTeamWorkTime: ' کارشناسان پشتیبانی به صورا 24 ساعته آماده ارائه خدمت به شما کاربران عزیز می باشند.',
  HelpSatrexUserLevels: 'راهنما سطوح کاربری ساترکس',
  'Total order amount': 'مبلغ کل سفارش',
  'Average price done': 'میانگین قیمت انجام شده',
  'the last situation': 'آخرین وضعیت',
  'Matching details of your order with ID': 'جزئیات مچینگ سفارش شما با شناسه ',
  'Please arrange the phrase opposite': 'لطفا عبارت روبرو را مرتب کنید',
  firstLevel: 'مرحله ی اول',
  secondLevel: 'مرحله ی دوم',
  TotalAssets: 'مجموع دارایی',
  mashhad: 'مشهد',
  'Withdrawal can not be more than your inventory!': 'میزان برداشت نمیتواند از موجودی شما بیشتر باشد!',
  'Information submitted successfully': 'اطلاعات با موفقیت ارسال شد',
  'Information deleted successfully': 'اطلاعات با موفقیت حذف شد',
  'Bank account successfully sent to admin': 'اکانت بانکی با موفقیت برای ادمین ارسال شد',
  'Request to verify review successfully cancelled': 'درخواست تأیید بررسی با موفقیت لغو شد',
  'Save and send for confirmation': 'ذخیره و ارسال برای تایید',
  'The contact information request was successfully canceled': 'درخواست بررسی اطلاعات تماس با موفقیت کنسل شد',
  'The review request was successfully sent to the admin': 'درخواست بررسی با موفقیت برای ادمین ارسال شد',
  'An error occurred while sending the validation code. Please try again': 'در ارسال کد صحت سنجی خطایی رخ داده است لطفا دوباره امتحان کنید',
  'Information successfully recorded': 'اطلاعات با موفقیت ثبت شد',
  'Email verified successfully': 'ایمیل با موفقیت تایید شد',
  'National card image uploaded successfully': 'تصویر کارت ملی با موفقیت آپلود شد',
  'Document review request was successfully canceled': 'درخواست بررسی مدارک با موفقیت کنسل شد',
  'Personal image uploaded successfully': 'تصویر شخصی با موفقیت آپلود شد',
  'Are you sure you want to send the following information?': 'از ارسال اطلاعات زیر اطمینان دارید؟',
  'Sorry, the system is currently unavailable. Please send your information to admin for review': 'متاسفانه سیستم درحال حاضر در دسترس نیست لطفا اطلاعات خود را برای بررسی ادمین ارسال کنید',
  'Your personal information has not been approved by the system, so to pass this step, upload the required photos and then send them to the admin.': ' اطلاعات شخصی شما مورد تایید سیستم قرار نگرفته لذا برای عبور از این مرحله عکس های مورد نیاز را بارگزاری و سپس برای ادمین ارسال کنید',
  'Admin check': 'بررسی ادمین',
  'The minimum amount allowed for the transaction is ': 'حداقل مبلغ مجاز برای انجام معامله برابر است با : ',
  'The request was successfully submitted ': 'درخواست با موفقیت ثبت شد ',
  'Total Fund': 'موجودی',
  commission: 'کارمزد',
  'Choose Network': 'انتخاب شبکه',
  'Referral code': 'کد معرف',
  Service: 'خدمات',
  'Invite friends': 'دعوت دوستان',
  'number of pages': 'تعداد صفحات',
  Page: 'صفحه',
  of: 'از',
  Show: 'نمایش',
  ghavamin: 'قوامین',
  toseeIns: 'موسسه اعتباری توسعه',
  bankmarkazi: 'مرکزی',
  hekmat: 'حکمت',
  gardeshgari: 'گردشگری',
  ToseSaderat: 'توسعه صادرات',
  iranzamin: 'ایران زمین',
  mehriranian: 'قرض الحسنه مهر ایران',
  'Invite Code': 'کد دعوت',
  'Invite Link': 'لینک دعوت',
  'User Code': 'کد کاربر',
  'Information saved successfully': 'اطلاعات با موفقیت ذخیره شد',
  'The request to verify personal information was successfully canceled': 'درخواست بررسی اطلاعات شخصی با موفقیت کنسل شد',
  'Please attach your personal photo': 'لطفا عکس شخصی خود  را ضمیمه کنید',
  'Please attach a photo of your national card': 'لطفا عکس کارت ملی خود را ضمیمه کنید',
  'The code was sent to you': 'کد به همراه شما ارسال شد',
  '4000 Tomans': '4000 تومان',
  'Minimum withdrawal amount': 'حداقل میزان برداشت',
  'Crypto Currency type': 'نوع رمزارز',
  'Help link Send personal image authentication': 'لینک راهنما ارسال تصویر شخصی احراز هویت',
  'If the deposit is successful, your Rial wallet will be charged. You will have access to the details of all your transactions on the account turnover page.': 'چنانچه واریز با موفقیت انجام شود کیف پول ریالی شما شارژ خواهد شد. در صفحه گردش حساب به جزییات تمام تراکنش های خود دسترسی خواهید داشت.',
  'User Level Guide': 'راهنمای سطوح کاربری',
  'Order canceled successfully': 'سفارش با موفقیت لغو شد',
  'Your contact information has been rejected by the admin': 'اطلاعات تماس شما مورد توسط ادمین رد شده است',
  'Your contact information has not been sent yet': 'اطلاعات تماس شما هنوز ارسال نشده است',
  'Your ID information has been sent to the admin and is awaiting review': 'اطلاعات شناسنامه ایی شما برای ادمین ارسال شده و در انتظار بررسی قرار گرفته است',
  'Your credentials have been rejected by the admin': 'اطلاعات شناسنامه ایی شما مورد توسط ادمین رد شده است',
  'Your ID has not yet been sent': 'اطلاعات شناسنامه ایی شما هنوز ارسال نشده است',
  'Your bank information has been sent to the admin and is awaiting review': 'اطلاعات بانکی شما برای ادمین ارسال شده و در انتظار بررسی قرار گرفته است',
  'Your bank information has been rejected by the admin': 'اطلاعات بانکی شما مورد توسط ادمین رد شده است',
  'Your bank information has not been sent yet': 'اطلاعات بانکی شما هنوز ارسال نشده است',
  'The request was successfully canceled': 'درخواست با موفقیت کنسل شد',
  'Repeat password does not match new password': 'تکرار پسورد با پسورد جدید تطابق ندارد',
  'Password changed successfully': 'پسورد با موفقیت تغییر کرد',
  'Mobile phone entry is mandatory': 'وارد کردن موبایل اجباری است',
  'The passwords entered do not match!': 'پسورد های وارد شده باهم تطابق ندارند!',
  'Password recovery': 'بازیابی پسورد',
  'Send code': 'ارسال کد',
  'Enter Auth Code': 'کد تایید را وارد کنید',
  'Fields are required': 'پر کردن فیلد ها الزامی است',
  'Please enable the option I am not a robot': 'لطفا گزینه ی من ربات نیستم را فعال کنید',
  'Mobile number must be entered without zero': 'شماره موبایل باید بدون صفر وارد شود',
  'Enter password is required': 'وارد کردن پسورد اجباری است',
  'The password entered does not equal the repetition': 'پسورد وارد شده با تکرار آن برابر نیست',
  'The login code was sent to your mobile number': 'کد ورود به شماره موبایل شما ارسال گردید ',
  'Sorting is required': 'مرتب کردن عبارت الزامی است',
  'Your request has been successfully submitted. Please wait for a backup response': 'درخواست شما با موفقیت ثبت شد لطفا منتظر جواب پشتیبان بمانید',
  'All fields are required': 'پر کردن تمام فیلد ها الزامی است',
  'Selecting a card number is required': 'انتخاب یک شماره کارت الزامی است',
  'Withdrawal value must be greater than zero': 'مقدار برداشت باید بزرگتر از صفر باشد',
  'A two-factor identification code is required': 'وارد کردن کد شناسایی دوعاملی الزامی است',
  'Withdrawal request sent successfully': 'درخواست برداشت با موفقیت ارسال شد',
  'Withdrawal amount can not be more than your balance!': 'مبلغ برداشت نمیتواند از موجودی شما بیشتر باشد!',
  'No verified bank account found!': '!حساب بانکی تایید شده ایی پیدا نشد',
  Male: 'مرد',
  'market-deal': 'بازارها',
  setting: 'تنظیمات',
  about: 'درباره ساترکس',
  Login: 'ورود',
  'contact-us': 'ارتباط با ما',
  'fast-dealing': 'معامله سریع',
  'send-ticket': 'ارسال پیام',
  ConfirmRequest: 'درانتظار بررسی',
  Accepted: 'تایید شده',
  EN: 'انگلیسی',
  FA: 'فارسی',
  Language: 'زبان',
  'Dark display mode': 'حالت نمایش تیره',
  Version: 'نسخه',
  policies: 'قوانین و شرایط استفاده',
  Cited: 'اشاره کرد',
  Home: 'صفحه اصلی',
  'Search in Satrex ...': 'جستجو در ساتکرس...',
  'Cancel review request': 'لغو درخواست بررسی',
  'Delete all accounts': 'حذف همه ی حساب ها',
  'Are you sure you want to delete all accounts?': 'آیا از پاک کردن همه ی حساب ها اطمینان دارید؟',
  'Delete all': 'حذف همه',
  'Touch for a while to perform the desired bank account operation': 'برای انجام عملیات اکانت بانکی مورد نظر برای مدتی لمس کنید',
  'please wait...': 'لطفا منتظر بمانید ...',
  Gender: 'جنسیت',
  submit: 'ارسال',
  'Information successfully verified': 'اطلاعات با موفقیت تایید شد',
  Female: 'زن',
  'Unfortunately, the system is not able to provide the service at the moment. Please send your photos first and then your personal information': 'متاسفانه سیستم درحال حاضر قادر به ارائه سرویس نمیباشد لطفا ابتدا عکس ها و سپس اطلاعات شخصی خود را ارسال کنید',
  Time: 'زمان',
  'working hours': 'ساعات کاری',
  'Market type': 'نوع بازار',
  Amount: 'مقدار',
  Volume: 'حجم',
  Order: 'ثبت سفارش',
  'Drag it to the left to perform operations on the market': 'برای انجام عملیات برروی مارکت آن را به چپ بکشید',
  'Search Crypto Currency ...': 'جستجو رمزارز...',
  'The password does not match the repetition': 'پسورد با تکرار آن تطابق ندارد',
  'Security code': 'کد امنیتی',
  back: 'بازگشت',
  'SMS login disabled': 'پیامک ورود غیر فعال شد ',
  'SMS login enabled': 'پیامک ورود فعال شد ',
  start: 'شروع',
  nationalCardImage: 'عکس کارت ملی',
  personalImageImage: 'عکس شخصی',
  'Submit to admin review': 'ارسال برای بررسی ادمین',
};

export default (props) => (
  dic[props]
);
