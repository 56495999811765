import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { IonButton } from '@ionic/react';
import { useWalletList } from '../../utils/queries';
import { LayoutState, walletDetailType } from '../../utils/interfaces';
import SatrexNumFormat from '../../components/SatrexNumFormat';
import Trans from '../../components/Trans';
import AppContext from '../../contexts/AppContext';
import Refresher from '../../components/Refresher';

const WalletDetails: React.FC = () => {
  const { data: walletList, isLoading: loading } = useWalletList();
  const { coinname } = useParams();
  const history = useHistory();
  const [globState, setGlobState] = useContext(AppContext);
  const [selectedCoin, setSelectedCoin] = useState<walletDetailType>();
  const lang = useSelector((state: LayoutState) => state.layout.language);
  const isEn = lang === 'EN';
  // console.log('walletList : ', walletList);
  useEffect(() => {
    if (walletList && walletList.data.length > 0) {
      setSelectedCoin(walletList.data.filter((item: walletDetailType) => item.assetEnglishTitle === coinname)[0]);
    }
    setGlobState({ ...globState, back: { status: true, page: '/wallet' } });
    return () => {
      setGlobState({ ...globState, marketDeal: null, back: { status: false, page: '' } });
    };
  }, [walletList]);

  const handleRedirection = (type: string) => {
    history.push({
      pathname: '/accounting',
      state: {
        slide: selectedCoin?.assetEnglishTitle === 'TOMAN' ? 1 : 0,
        type: type === 'deposit' ? 0 : 1,
        selectedCoin,
      },
    });
  };
  return (
    <>
      <div className="container">
        <Refresher
          queries={['getWalletInfo']}
        />
        <div className="section" style={{ height: '100%' }}>
          {
            selectedCoin
            && (
              <>
                <div className="walletDetailTitle">
                  <img src={selectedCoin.assetImageAddress} alt="" />
                  <h4>{isEn ? selectedCoin.assetPersianTitle : selectedCoin.assetEnglishTitle}</h4>
                </div>

                <div className="walletDetailInformationRow">
                  <span>موجودی</span>
                  <span>
                    <SatrexNumFormat
                      num={selectedCoin.totalFund}
                    />
                  </span>
                </div>
                <div className="walletDetailInformationRow">
                  <span>موجودی (تومان)</span>
                  <span>
                  <SatrexNumFormat
                    num={selectedCoin.totalFundPriceInToman}
                  />
                  </span>
                </div>
                <div className="walletDetailInformationRow">
                  <span>موجودی (تتر)</span>
                  <span>
                    <SatrexNumFormat
                      num={selectedCoin.totalFundPriceInTether}
                    />
                  </span>
                </div>
                <div className="walletDetailInformationRow">
                  <span>قابل معامله</span>
                  <span>
                    <SatrexNumFormat
                      num={selectedCoin.totalTradable}
                    />
                  </span>
                </div>
                <div className="walletDetailInformationRow">
                  <span>قابل معامله(تومان)</span>
                  <span>
                    <SatrexNumFormat
                      num={selectedCoin.totalTradablePriceInToman}
                    />
                  </span>
                </div>
                <div className="walletDetailInformationRow">
                  <span>قابل معامله(تتر)</span>
                  <span>

                    <SatrexNumFormat
                      num={selectedCoin.totalTradablePriceInTether}
                    />
                  </span>

                </div>
              </>
            )
          }
          <div style={{
            marginTop: 0, position: 'fixed', bottom: 80, width: '100%',
          }}
          >
            <div className="row" data-tut="marketSetOrder">
              <div style={{ padding: 10, width: '50%' }}>
                <IonButton
                  className="GradientsRedButton"
                  style={{ width: '100%' }}
                  onClick={() => handleRedirection('deposit')}
                >
                  <Trans>واریز</Trans>
                </IonButton>
              </div>
              <div style={{ padding: 10, width: '50%' }}>
                <IonButton
                  className="GradientsButton"
                  style={{ width: '100%' }}
                  onClick={() => handleRedirection('withdrawal')}
                >
                  <Trans>برداشت</Trans>
                </IonButton>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletDetails;
