import home from './Main/tourConfig';
import reports from './Reports/tourConfig';
import security from './Security/tourConfig';
import wallet from './Wallet/tourConfig';
import fastDealing from './FastDealing/tourConfig';
import markets from './Markets/tourConfig';
import market from './Market/tourConfig';
import profile from './Profile/tourConfig';
import marketDeal from './Market/MarketDeal/tourConfig';
import accounting from './Accounting/tourConfig';

export default {
  home,
  // setting,
  market,
  //
  // support,
  accounting,
  // messages,
  // about,
  // policies,
  // guide,
  wallet,
  security,
  markets,
  reports,
  profile,
  'market-deal': marketDeal,
  // 'send-ticket':sendTicket,
  'fast-dealing': fastDealing,
  // 'contact-us':contactUs,
  // 'no-internet':noInternet,
};
