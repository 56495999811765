import React from 'react';
import sliderTourConfig from '../../components/slider/tourConfig';

export default [
  ...sliderTourConfig,
  {
    selector: '[data-tut="searchBarCoin"]',
    content: () => (
      <div>
        <p>برای یافتن کوین مورد نظر میتوانید از این بخش استفاده کنید</p>
      </div>
    ),
  },
  {
    selector: '[data-tut="tabRowSecrtion"]',
    content: () => (
      <div>
        <p> با کلیک بر روی هر کدام از این قسمت ها میتوانید به تب های متفاوت دسترسی داشته باشید</p>
        <p>
<span style={{ color: 'red' }}> نکته:</span>
<span>در صورتی که تبی مشاهده نمیشود میتوانید بخش را به سمت چپ و یا راست بکشید</span>
        </p>
      </div>
    ),
  },
  {
    selector: '[data-tut="marketsRow"]',
    content: () => (
        <p>برای انجام عملیات بر روی مارکت ها میتوانید آن را به چپ بکشید</p>
    ),
  },

];
