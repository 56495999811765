import React from 'react';
import sliderTourConfig from '../../components/slider/tourConfig';

export default [
  ...sliderTourConfig,
  {
    selector: '[data-tut="depositWithdrawalCryptoSection"]',
    content: () => (
      <div>
        <p>برای واریز و یا برداشت رمز ارز از این بخش استفاده کنید</p>
      </div>
    ),
  },
{
    selector: '[data-tut="depositMoneySection"]',
    content: () => (
      <div>
        <p>برای واریز وجه از این بخش استفاده کنید</p>
      </div>
    ),
  },
{
    selector: '[data-tut="withdrawalSection"]',
    content: () => (
      <div>
        <p>برای برداشت وجه از این بخش استفاده کنید</p>
      </div>
    ),
  },
{
    selector: '[data-tut="turnoverSection"]',
    content: () => (
      <div>
        <p>برای مشاهده گردش حساب ها از این بخش استفاده کنید</p>
      </div>
    ),
  },
  {
    selector: '[data-tut="editBankInfo"]',
    content: () => (
      <div>
        <p>تمامی فرایند های مربوط به کارت بانکی در این قسمت انجام میشود </p>
      </div>
    ),
  },
  {
    selector: '[data-tut="requestsSection"]',
    content: () => (
      <div>
        <p>لیست تمامی درخواست ها را در اینجا مشاهده کنید</p>
      </div>
    ),
  },
];
