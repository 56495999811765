import React, { useContext, useEffect, useState } from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {
  chevronBackOutline,
  chevronForwardOutline,
  notificationsOutline,
} from 'ionicons/icons';
import { useParams, useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import Trans from '../components/Trans';
import logo from '../images/logo.png';
import logoText from '../images/loginComponent/logoText.svg';
import AppContext from '../contexts/AppContext';

import { getCoinPriceApi, getSupportedCoinList } from '../redux/actions/CoinActions';
import RootTourConfig from './RootTourConfig';
import TourGuide from '../components/TourGuide';
import satrexText from '../images/about/satrex2.png';
import { getUserLevel } from '../redux/actions/UserActions';

const styles = {
  slideContainer: {
    height: '100vh',
    width: '100%',
  },
  slide: {
    minHeight: 100,
    color: '#fff',
  },
  scroll: {

    overflow: 'scroll',
  },
};

export default ({ children }) => {
  const dispatch = useDispatch();
  const { name } = useParams();
  const location = useLocation();
  const [globState, setGlobState] = useContext(AppContext);
  const history = useHistory();
  const language = useSelector((state) => state.layout.language);
  const isLtr = language === 'EN';
  const [firstLoadedPage, setFirstLoadedPage] = useState({});

  // const fingerPrintTest = ()=>{
  //     Keyboard.show()
  // }

  useEffect(() => {
    dispatch(getUserLevel());
    dispatch(getSupportedCoinList());
    dispatch(getCoinPriceApi());

    let temp = {
      home: false,
      market: false,
      accounting: false,
      wallet: false,
      security: false,
      markets: false,
      reports: false,
      profile: false,
      'market-deal': false,
      'fast-dealing': false,
    };
    const storedLoadedPages = localStorage.getItem('loadedPages');
    if (storedLoadedPages) {
      temp = JSON.parse(storedLoadedPages);
    } else {
      localStorage.setItem('loadedPages', JSON.stringify(temp));
    }
    setFirstLoadedPage(temp);

    if (temp[location.pathname.split('/')[1]] !== undefined && temp[location.pathname.split('/')[1]] === false) {
      temp = { ...temp, [location.pathname.split('/')[1]]: true };
      setTimeout(() => {
        setFirstLoadedPage(temp);
      }, 1000);
    }
    // console.log("temp : ", temp);

    return () => {
      localStorage.setItem('loadedPages', JSON.stringify(temp));
    };
  }, [name]);

  // console.log("firstLoadedPage : ", firstLoadedPage);
  return (
    <>
      <IonPage>

        <IonHeader>
          <IonToolbar
            className={name === 'home' ? 'homeToolbar' : 'toolbar'}
            color="white"
          >
            {

              globState.back.status
              && (
                <IonButtons
                  slot="start"
                  style={{ paddingRight: 5, cursor: 'pointer' }}
                  onClick={() => history.push(globState.back.page)}
                >
                  <IonIcon
                    icon={isLtr ? chevronBackOutline : chevronForwardOutline}
                    style={{ fontSize: 22, color: 'white' }}
                  />
                </IonButtons>
              )
            }
            <IonButtons slot="start" data-tut="menuButton">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>
              <span style={{
                color: 'white',
                fontSize: 19,
                position: 'relative',
                top: 2,
              }}
              >
                <Trans>
                  {name !== 'home' ? location.pathname.split('/')[1]
                    : (
                      <div style={{ flexDirection: 'row', position: 'relative', top: 1 }}>
                        <motion.img
                          transition={{
                            ease: 'easeInOut',
                            duration: 2,
                            flip: 'Infinity',
                            repeatDelay: 5,
                          }}
                          initial={{ rotateY: 0, rotateZ: 0 }}
                          animate={{ rotateY: 360, rotateZ: 360 }}
                          src={logo}
                          style={{ width: 30 }}
                          alt=""
                        />
                        <img
                          src={logoText}
                          style={{
                            width: 120, position: 'relative', top: 5, height: 32,
                          }}
                          alt=""
                        />

                      </div>
                    )}
                </Trans>
              </span>
            </IonTitle>
            <IonButtons slot="end" style={{ marginLeft: 10, marginRight: 10 }}>
              {
                RootTourConfig[location.pathname.split('/')[1]] && firstLoadedPage.home !== undefined
                && (
                  <TourGuide
                    tourConfig={RootTourConfig[location.pathname.split('/')[1]]}
                    cssClass={RootTourConfig[location.pathname.split('/')[1]] !== 'home' ? 'whiteTourBtn' : ''}
                    firstLoad={firstLoadedPage[location.pathname.split('/')[1]]}
                  />
                )
              }
            </IonButtons>
            <IonButtons slot="end">
              <IonIcon
                data-tut="notifSection"
                icon={notificationsOutline}
                style={{ color: 'white', fontSize: 22, marginLeft: 9 }}
              />
            </IonButtons>

          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{name}</IonTitle>
            </IonToolbar>
          </IonHeader>
          {
            name === 'home'
            && <div className="homeCircle" />
          }
          <div
            style={{
              position: 'relative', zIndex: 2, top: 45, minHeight: 'calc(100% - 50px)',
            }}
            id="routes"
          >
            <div className="satrexTextDiv">
              <img src={satrexText} style={{ width: '100%' }} alt="" />
            </div>
            {children}
            {/* <IonButton onClick={fingerPrintTest}>Scan barcode</IonButton> */}

          </div>
          {/* <IonFab vertical="bottom"  data-tut="quickAccess"  horizontal="end" edge slot="fixed"> */}
          {/*    <IonFabButton> */}
          {/*        <IonIcon icon={helpCircleOutline} style={{color:'#2988ff',fontSize:35}} /> */}
          {/*    </IonFabButton> */}
          {/* </IonFab> */}
        </IonContent>

      </IonPage>
    </>
  );
};
