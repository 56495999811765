import CoinpaprikaAPI from '@coinpaprika/api-nodejs-client';
import { satrexApi } from '../../utils/ApiConfig';
import { satrexGreen } from '../../styles/colors';

export const GET_SUPPORTED_COIN_LIST = 'GET_SUPPORTED_COIN_LIST';
export const GET_COIN_PRICE_API = 'GET_COIN_PRICE_API';

export const getSupportedCoinList = () => (dispatch) => {
        satrexApi()
            .post('Board/GetPairsDifferentialPriceForLast24Hours')
            .then(({ data }) => {
                dispatch({
                    type: GET_SUPPORTED_COIN_LIST,
                    payload: data.data,
                });
            });
    };

export const getCoinPriceApi = () => (dispatch, getState) => {
        const client = new CoinpaprikaAPI();
        // axios.get('https://api.coinpaprika.com/v1/ticker').then((data)=>{
        //   console.log('answer of coin api ',data)
        // })
        client.getTicker().then((data) => {
            const coinPaprikaList = [];
            let dollarPrice = 0;
            for (let i = 0; i < 2500; i++) {
                const supportedCoins = getState().coin.coinsList.map((item) => item.replace(/\s/g, ''));
                if (data[i] && data[i].name === 'Iranian Rial') {
                    dollarPrice = data[i];
                }

                const coinIndex = supportedCoins.indexOf(
                    data[i].name.toLowerCase().replace(/\s/g, ''),
                    0,
                );
                if (coinIndex >= 0) {
                    coinPaprikaList.push({
                        name: data[i].name,
                        symbol: data[i].symbol,
                        change: parseFloat(data[i].percent_change_24h ?? 0),
                        color: data[i].percent_change_24h >= 0 ? satrexGreen : 'red',
                        price: parseFloat(data[i].price_usd).toFixed(2),
                        totalSupply: data[i].total_supply,
                        rank: data[i].rank,
                        marketCapUsd: data[i].market_cap_usd,
                        pair: `${data[i].symbol}USD`,
                        pairId: getState().coin.supportedCoinList.filter((item) => item.sourceAssetSymbol === data[i].symbol && item.destinationAssetSymbol === 'TOMAN')[0]?.pairId,
                    });
                }
                if (i > 2498) {
                    dispatch({
                        type: GET_COIN_PRICE_API,
                        payload: { coinPaprikaList, dollarPrice },
                    });
                    break;
                }
            }
        });
    };
