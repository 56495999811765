export const USER_LANGUAGE = 'USER_LANGUAGE';
export const ADD_REDIRECTS = 'ADD_REDIRECTS';
export const CLEAN_REDIRECTS = 'CLEAN_REDIRECTS';

export const setLanguage = (language) => {
    localStorage.setItem('language', language);
    return (dispatch) => {
        dispatch({
            type: USER_LANGUAGE,
            payload: language,
        });
    };
};

export const addRedirection = ({ fromUrl, toUrl }) => (dispatch) => {
        dispatch({
            type: ADD_REDIRECTS,
            payload: { fromUrl, toUrl },
        });
    };
export const cleanRedirects = () => (dispatch) => {
        dispatch({
            type: CLEAN_REDIRECTS,
        });
    };
