import starkString from 'starkstring';
import moment from 'moment';
import _ from 'lodash';
import { defaultApi, satrexApi } from './ApiConfig';
import satrexToast from '../components/satrexToast';
import { addFavoriteMark, removeFavMarket, setFavoriteMarkets } from '../redux/actions/MarketActions';
import { Store } from '../redux/store';
import { transFn } from '../components/Trans';
import convertDate from '../components/convertDate';
import { setJwtToken } from '../redux/actions/LoginActions';

export async function loginWithPhoneAndPassword(mobile, password) {
  try {
    const { data: user } = await defaultApi().post('/Token/Get', {
      usernameOrEmail: starkString(mobile.replace(/\s/g, '').replace('+', '00')).englishNumber().toString(),
      password: starkString(password).englishNumber().toString(),
    });

    if (user.isSuccess) {
      Store.dispatch(setJwtToken(
        user.data.jwtToken.tokenValue,
        user.data.refreshToken.tokenValue,
      ));
      localStorage.setItem(
        'bearerToken',
        user.data.jwtToken.tokenValue,
      );
      localStorage.setItem(
        'refreshToken',
        starkString(user.data.refreshToken.tokenValue)
          .toString(),
      );
      localStorage.setItem(
        'expirationDate',
        moment()
          .add(user.data.jwtToken.expiresInSeconds + 120, 'seconds')
          .format('DD/MM/YYYY HH:mm:ss'),
      );
      return true;
    }
    user.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (err) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
}

export const getBankAccounts = async () => {
  const { data } = await satrexApi().post('/BankAccounts/GetList');
  if (data.isSuccess) return data.data;
};

export const getUserMessages = async () => {
  try {
    const { data } = await satrexApi().post('/Ticket/GetList');
    return data.data;
  } catch (err) {
    return [];
  }
};

export const getWalletInfo = async () => {
  try {
    const { data } = await satrexApi().post('/Wallet/GetAllSpotAssetsWithDetailsList');
    // console.log('wallet data: ', data);
    return data;
  } catch (err) {
    return err;
  }
};

export const getWalletSummary = async () => {
  try {
    const { data } = await satrexApi().post('/Dashboard/GetAllSpotAssetsWithDetailsList');
    console.log('wallet data: ', data);
    return data;
  } catch (err) {
    return err;
  }
};

export const getMarketsInfo = async () => {
  const { data } = await satrexApi()
    .post('/Board/GetPairsDifferentialPriceForLast24Hours');
  // return data.data
  if (data.isSuccess) {
    Store.dispatch(setFavoriteMarkets(
      data.data.filter((item) => item.pairIsFavorite === true),
    ));

    return data.data;
  }
  return false;
};

export const removeFavoriteMarket = async (row) => {
  try {
    const { data } = await satrexApi()
      .post('/Pair/RemoveFromFavorite', {
        pairId: row.pairId,
      });

    if (data.isSuccess) {
      satrexToast(transFn('marketRemovedFromFav'), 'success');
      Store.dispatch(removeFavMarket(row));
      row.pairIsFavorite = false;

      return false;
    }
  } catch (err) {
    return false;
  }
};

export const addFavMarket = async (row) => {
  try {
    const { data } = await satrexApi()
      .post('/Pair/AddToFavorite', {
        pairId: row.pairId,
      });
    if (data.isSuccess) {
      Store.dispatch(addFavoriteMark(row));

      row.pairIsFavorite = true;
      satrexToast(transFn('marketAddToFav'), 'success');
      return true;
    }
    return false;
  } catch (err) {
    satrexToast(transFn('failedInMission'), 'success');
    return false;
  }
};

export const getHighLowPrice = async (id) => {
  try {
    const { data } = await satrexApi()
      .post('/Board/GetHighAndLowPriceForPairInLast24Hours', {
        pairId: id,
      });
    return data;
  } catch (err) {

  }
};

export const getCompletedOrdrers = async (id) => {
  try {
    const { data } = await satrexApi()
      .post('/Board/GetSpotLimitLastCompletedOrders', {
        count: 10,
        pairId: id,
      });
    return data;
  } catch (err) {

  }
};

export const getCurrentOrders = async (id) => {
  try {
    const { data } = await satrexApi()
      .post('/Board/GetSpotLimitBoardAccumulative', {
        count: 6,
        pairId: id,
      });
    return data.data;
  } catch (err) {

  }
};

export const getTotalBalance = async (id) => {
  try {
    const { data } = await satrexApi()
      .post('/Wallet/GetSpotBalanceWithExchangeFeeList', {
        pairId: id,
      });
    return data;
  } catch (err) {

  }
};

export const getTodaysOrders = async (id) => {
  try {
    const { data } = await satrexApi()
      .post('/Order/GetOrderListByDate', {
        fromDateUtc: starkString(moment().format('YYYY-MM-DD'))
          .englishNumber()
          .toString(),
        toDateUtc: starkString(moment().add(1, 'days').format('YYYY-MM-DD'))
          .englishNumber()
          .toString(),
      });
    return data;
  } catch (err) {

  }
};

export const getLoginHistory = async () => {
  try {
    const { data } = await satrexApi()
      .post('/Log/GetList', {
        fromDateTime: '1990-08-29T15:03:43.049Z',
        toDateTime: starkString(moment().add('1', 'day').format('YYYY-MM-DD'))
          .englishNumber()
          .toString(),
      });
    return data;
  } catch (err) {

  }
};

export const getReportOrderList = async () => {
  try {
    const { data } = await satrexApi().post('/Order/GetOrderListByDate', {
      fromDateUtc: starkString(moment('1995-01-01').format('YYYY-MM-DD'))
        .englishNumber()
        .toString(),
      toDateUtc: starkString(moment().add(1, 'days').format('YYYY-MM-DD'))
        .englishNumber()
        .toString(),
    });
    return data;
  } catch (err) {

  }
};

export const getReportOpenOrders = async () => {
  try {
    const { data } = await satrexApi().post('/Order/GetOpenOrderList');
    return data;
  } catch (err) {

  }
};

export const getReportFastOrders = async () => {
  try {
    const { data } = await satrexApi().post('/FastExchange/GetList');
    return data;
  } catch (err) {

  }
};

export const getUserFee = async () => {
  try {
    const { data } = await satrexApi().post('/Dashboard/GetUserQuickView');
    return data.data.userFinancialLevel.exchangeFeePercent;
  } catch (err) {

  }
};

export const getTotalFund = async () => {
  try {
    const { data } = await satrexApi()
      .post('/Wallet/GetTomanTotalFoundAndTotalTradable');
    return data.data.totalTradable;
  } catch (err) {

  }
};

export const getCoinList = async () => {
  try {
    const { data } = await satrexApi().post('/Asset/GetList');
    return data;
  } catch (err) {

  }
};

export const getFastExchangePrice = async (id) => {
  const { data } = await satrexApi()
    .post('/FastExchange/GetFastExchangePrice', {
      assetId: id,
    });
  return data;
};

export const getAssetBalance = async (id) => {
  const { data } = await satrexApi()
    .post('/Wallet/GetAssetBalance', {
      assetId: id,
    });
  return data;
};

export const getDepartments = async () => {
  const { data } = await satrexApi()
    .post('/Department/GetList', {});
  return data.data;
};

export const getTotalReceivedAsset = async (id, netId) => {
  try {
    const { data } = await satrexApi()
      .post('/DepositAsset/GetTotalReceivedAsset', {
        assetId: id,
        networkId: netId,
      });
    return data;
  } catch (e) {

  }
};

export const getWalletAddress = async (id, net) => {
  try {
    const { data } = await satrexApi()
      .post('/DepositAsset/GetWalletAddress', {
        assetId: id,
        networkId: net,
      });
    if (data.isSuccess) {
      return data;
    }
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const getQualifyLevel = async () => {
  try {
    const { data } = await satrexApi()
      .post('/QualifyLevel/Get');
    return data;
  } catch (e) {
    return false;
  }
};

export const getTurnoverList = async (id) => {
  const { data } = await satrexApi().post('/TurnOver/GetList', {
    fromDateTime: starkString(moment('1995-01-01').format('YYYY-MM-DD'))
      .englishNumber()
      .toString(),
    toDateTime: starkString(moment().add(1, 'days').format('YYYY-MM-DD'))
      .englishNumber()
      .toString(),
  });
  return data;
};

export const getRequestList = async () => {
  const { data } = await satrexApi().post('/TurnOver/GetRequestsList', {
    fromDateTime: starkString(moment('1995-01-01').format('YYYY-MM-DD'))
      .englishNumber()
      .toString(),
    toDateTime: starkString(moment().add(1, 'days').format('YYYY-MM-DD'))
      .englishNumber()
      .toString(),
  });
  return data.data.reverse();
};

export const sendSmsCode = async (mobile) => {
  try {
    const { data } = await defaultApi()
      .post('/User/SendMobileSecretCode', {
        mobile: mobile.replace(/\s/g, '').replace('+', '00'),
      });
    if (data.isSuccess) {
      satrexToast('کد پیامکی به همراه شما ارسال شد', 'success');
      return true;
    }
    return false;
  } catch (err) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const registerValidate = async (mobile, password) => {
  try {
    const { data } = await defaultApi().post('/User/RegisterValidation', {
      mobile: mobile.replace(/\s/g, '').replace('+', '00'),
      password,
    });

    if (data.isSuccess && data.data) {
      return await sendSmsCode(mobile);
    }
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const register = async (mobile, password, smsSecretCode, refralCode) => {
  try {
    const { data } = await defaultApi().post('/User/Register', {
      mobile: mobile.replace(/\s/g, '').replace('+', '00'),
      smsSecretCode: smsSecretCode.replace(/-/g, ''),
      password,
      referralCode: refralCode,
    });

    if (data.isSuccess) {
      return await loginWithPhoneAndPassword(mobile, password);
    }
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const submitFastExchangeOrder = async (id, amount, type) => {
  try {
    const { data } = await satrexApi()
      .post('/FastExchange/Create', {
        AssetId: id,
        Volume: amount,
        orderType: type,
      });
    if (data.isSuccess) return satrexToast(transFn('orderSuccessfullyRegistered'), 'success');
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
  } catch (err) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const changePassword = async (oldPassword, newPassword) => {
  try {
    const { data } = await satrexApi()
      .post('/User/ChangePassword', {
        oldPassword,
        newPassword,
      });
    if (data.isSuccess) return satrexToast(transFn('Password changed successfully'), 'success');
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const getSupportedNetwork = async (id) => {
  try {
    const { data } = await satrexApi().post('/Asset/GetSupportedNetworks', { assetId: id });

    if (data.isSuccess) return [data.data, _.minBy(data.data, 'networkFee').network.id];
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const sendBankAccountToAdmin = async (id) => {
  try {
    const { data } = await satrexApi().post('/BankAccounts/SetConfirmRequest', {
      id,
    });
    if (data.isSuccess) {
      satrexToast(transFn('Bank account successfully sent to admin'), 'success');
      return {
        id,
        lastConfirmationType: 'ConfirmRequest',
      };
    }
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const savingBankAccount = async (cardNumber, iban) => {
  try {
    const { data } = await satrexApi()
      .post('/BankAccounts/Add', {
        iban,
        cardNumber: cardNumber.toString().replace(/ /g, ''),
      });

    if (data.isSuccess) {
      return await sendBankAccountToAdmin(data.data);
    }
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const removingAccount = async (id) => {
  try {
    const { data } = await satrexApi().post('/BankAccounts/Remove', { id });

    if (data.isSuccess) {
      satrexToast(transFn('"Information deleted successfully"'), 'success');
      return true;
    }
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const cancelAccount = async (id) => {
  try {
    const { data } = await satrexApi()
      .post('/BankAccounts/CancelConfirmRequest', {
        id,
      });

    if (data.isSuccess) {
      satrexToast(transFn('Request to verify review successfully cancelled'), 'success');
      return { lastConfirmationType: 'Editable' };
    }
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const getPersonalInfo = async () => {
  try {
    const { data } = await satrexApi().post('/PersonalInfo/Get');
    return data.data;
  } catch (e) {

  }
};
export const updatePersonalInfo = async ({
                                           firstName,
                                           lastName,
                                           nationalId,
                                           citizenId,
                                           isForeignCitizen,
                                           phone,
                                           birthdate,
                                           gender,
                                         }) => {
  try {
    const { data } = await satrexApi().post('/PersonalInfo/Update', {
      firstName,
      lastName,
      nationalId,
      citizenId,
      isForeignCitizen,
      phone,
      birthdate: starkString(convertDate(starkString(birthdate.replace(/\//g, '-')).englishNumber().toString())).englishNumber().toString(),
      gender,
    });
    if (data.isSuccess) {
      return true;
    }
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const sendSmsForIndentifying = async () => {
  try {
    const { data } = await satrexApi().post(
      '/ExternalAuthorization/SendSmsForIdentityAuthorization',
    );
    if (data.isSuccess) return true;
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const codeVarification = async (code) => {
  try {
    const { data } = await satrexApi().post(
      '/ExternalAuthorization/VerifyOtpForIdentityAuthorization',
      {
        otp: code.replace(/-/g, ''),
      },
    );
    if (data.isSuccess) return true;
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('Unfortunately, the system is not able to provide the service at the moment. Please send your photos first and then your personal information'), 'error');
    return 'finotekFailed';
  }
};

export const getUserImage = async () => {
  try {
    const { data } = await satrexApi().post('/UserImage/Get');
    if (data.isSuccess) {
      return {
        nationalCard: data.data.nationalCardImageAddress,
        personalImage: data.data.personalImageAddress,
        lastConfirmationType: data.data.lastConfirmationType,
      };
    }
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {

  }
};

export const uploadNationalCardImage = async (nationalCardImage) => {
  try {
    const formData = new FormData();
    formData.append('file', nationalCardImage);
    const { data } = await satrexApi().post('/UserImage/UploadNationalCardImage', formData);
    if (data.isSuccess) {
      return true;
    }
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const uploadPersonalImage = async (personalImage) => {
  try {
    const formData = new FormData();
    formData.append('file', personalImage);
    const { data } = await satrexApi().post('/UserImage/UploadPersonalImage', formData);
    if (data.isSuccess) {
      return true;
    }
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const sendImagesConfirmRequest = async () => {
  try {
    const { data } = await satrexApi().post('/UserImage/SetConfirmRequest');
    if (data.isSuccess) return true;
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const getProvices = async () => {
  try {
    const { data } = await satrexApi().post('/Province/GetList');
    if (data.isSuccess) return data.data.provinces;
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {

  }
};

export const getCities = async (id) => {
  if (id && id !== '0') {
    try {
      const { data } = await satrexApi().post(`/City/GetCitiesByProvince?provinceId=${id}`);
      if (data.isSuccess) return data.data;
      data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
      return false;
    } catch (e) {

    }
  }
};

export const updateAddressInfo = async ({
                                          cityId, address, postalCode, phone,
                                        }) => {
  try {
    const { data } = await satrexApi()
      .post('/AddressInfo/Update', {
        cityId,
        address,
        postalCode,
        phone,
      });
    if (data.isSuccess) return true;
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const getAddressInfo = async () => {
  try {
    const { data } = await satrexApi().post('/AddressInfo/Get');
    if (data.isSuccess) return data.data;
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {

  }
};

export const getTotalFundAndTradable = async () => {
  try {
    const { data } = await satrexApi()
      .post('/Wallet/GetTomanTotalFoundAndTotalTradable');
    if (data.isSuccess) return data.data;
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {

  }
};

export const getMaximumWithdrawalPerDayInToman = async () => {
  try {
    const { data } = await satrexApi()
      .post('/InternalWithdraw/GetMaximumWithdrawalPerDayInToman');
    if (data.isSuccess) return data.data;
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {

  }
};

export const sendMobileSecretCodeForRegisteredUser = async () => {
  try {
    const { data } = await satrexApi()
      .post('/User/SendMobileSecretCodeForRegisteredUser', {});
    if (data.isSuccess) {
      satrexToast(transFn('The code was sent to you'), 'success');
      return true;
    }
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const setWithdrawalRequest = async ({
                                             requestDate, withdrawalAmount, bankId, twoFactorCode,
                                           }) => {
  try {
    const { data } = await satrexApi().post('/InternalWithdraw/Create', {
      withdrawDateTimeAtUtc: starkString(convertDate(starkString(requestDate.replace(/\//g, '-')).englishNumber().toString())).englishNumber().toString(),
      price: withdrawalAmount,
      bankAccountId: bankId,
      twoFactorAuthSecretCode: twoFactorCode,
    });
    if (data.isSuccess) {
      satrexToast(transFn('Withdrawal request sent successfully'), 'success');
      return true;
    }
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const setWithdrawalCryptoRequest = async ({
                                                   assetId,
                                                   volume,
                                                   destinationWalletAddress,
                                                   withdrawalNetwork,
                                                   twoFactorCode,
                                                 }) => {
  try {
    const { data } = await satrexApi().post('/ExternalWithdraw/Create', {
      assetId,
      volume,
      externalDestinationWalletAddress: destinationWalletAddress,
      twoFactorAuthSecretCode: twoFactorCode,
      networkId: withdrawalNetwork,
    });
    if (data.isSuccess) {
      satrexToast(transFn('WithdrawalRequestRegisteredSuccessfully'), 'success');
      return true;
    }
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const setMarketOrder = async ({ orderType, pairId, volume }) => {
  try {
    const { data } = await satrexApi()
      .post('/Order/SetMarketOrder', {
        orderType,
        pairId,
        volume,
      });
    if (data.isSuccess) {
      satrexToast(transFn('orderSuccessfullyRegistered'), 'success');
      return true;
    }
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};
export const setLimitOrder = async ({
                                      price, orderType, pairId, volume,
                                    }) => {
  try {
    const { data } = await satrexApi()
      .post('/Order/SetLimitOrder', {
        orderType,
        pairId,
        volume,
        price,
      });
    if (data.isSuccess) {
      satrexToast(transFn('orderSuccessfullyRegistered'), 'success');
      return true;
    }
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const getOpenOrderList = async (pairId) => {
  try {
    const { data } = await satrexApi().post('/Order/GetOpenOrderList', { pairId });
    if (data.isSuccess) return data.data;
    return false;
  } catch (e) {
    return false;
  }
};

export const DisableLoginByOtp = async () => {
  try {
    const { data } = await satrexApi().post('/User/DisableLoginByOtp');
    if (data.isSuccess) {
      satrexToast(transFn('SMS login disabled'), 'success');
      return true;
    }
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};
export const EnableLoginByOtp = async () => {
  try {
    const { data } = await satrexApi().post('/User/EnableLoginByOtp');
    if (data.isSuccess) {
      satrexToast(transFn('SMS login enabled'), 'success');
      return true;
    }
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const getOtpStatus = async () => {
  try {
    const { data } = await satrexApi().post('/User/GetLoginByOtpStatus');
    if (data.isSuccess) return data.data.isEnable;
    return 'error';
  } catch (e) {
    return 'error';
  }
};

export const sendTicket = async ({
                                   departmentId, title, message, priority,
                                 }) => {
  try {
    const { data } = await satrexApi()
      .post('/Ticket/Create', {
        departmentId,
        title,
        message,
        priority,
      });
    if (data.isSuccess) {
      satrexToast(transFn('Your request has been successfully submitted. Please wait for a backup response'), 'success');
      return true;
    }
    data.Errors.forEach((item) => satrexToast(item.Description, 'error'));
    return false;
  } catch (e) {
    satrexToast(transFn('failedInMission'), 'error');
    return false;
  }
};

export const getEmail = async () => {
  try {
    const { data } = await satrexApi().post('/User/GetEmail');
    if (data.isSuccess) {
      return data;
    }
    return {};
  } catch (e) {
    return {};
  }
};

export const updateEmail = async (emailAddress) => {
  try {
    const { data } = await satrexApi().post('/User/UpdateEmail', {
      emailAddress,
    });
    if (data.isSuccess) return data;

      data.Errors.forEach((err) => {
        satrexToast(err.Description, 'error');
      });
      return false;
  } catch (e) {
    return false;
  }
};

export const varifyEmail = async (email, secretCode) => {
  try {
    const { data } = await satrexApi().post('/User/VerifyEmail', {
      email,
      secretCode: secretCode.replace(/-/g, ''),
    });
    if (data.isSuccess) return true;
    return data.Errors.forEach((err) => {
      satrexToast(err.Description, 'error');
    });
  } catch (e) {
    return false;
  }
};

export const getSampleUserImages = async () => {
  try {
    const { data } = await satrexApi().post('/UserImage/GetSampleUserImages');
    if (data.isSuccess) return data.data.personalImageSampleImageAddress;
    return false;
  } catch (e) {
    return false;
  }
};

export const setPersonalInfoConfirmReq = async () => {
  try {
    const { data } = await satrexApi().post('/PersonalInfo/SetConfirmRequest');
    if (data.isSuccess) {
      satrexToast('درخواست بررسی با موفقیت برای ادمین ارسال شد', 'success');
      return true;
    }
    return data.Errors.forEach((err) => {
      satrexToast(err.Description, 'error');
    });
  } catch (e) {
    return false;
  }
};
export const setContactInfoConfirmReq = async () => {
  try {
    const { data } = await satrexApi().post('/AddressInfo/SetConfirmRequest');
    if (data.isSuccess) {
      satrexToast('درخواست بررسی با موفقیت برای ادمین ارسال شد', 'success');
      return true;
    }
    return data.Errors.forEach((err) => {
      satrexToast(err.Description, 'error');
    });
  } catch (e) {
    return false;
  }
};
export const sendEmailVerificationCode = async (email) => {
  try {
    const { data } = await satrexApi().post('/User/SendEmailVerificationCode', {
      emailAddress: email,
    });
    if (data.isSuccess) {
      // satrexToast('', 'success');
      return true;
    }
     data.Errors.forEach((err) => {
      satrexToast(err.Description, 'error');
    });
    return false;
  } catch (e) {
    return false;
  }
};

export const cancelPersonalInfoConfirmRequest = async () => {
  try {
    const { data } = await satrexApi().post('/PersonalInfo/CancelConfirmRequest');
    if (data.isSuccess) {
      satrexToast('درخواست بررسی با موفقیت لغو شد', 'success');
      return true;
    }
     data.Errors.forEach((err) => {
      satrexToast(err.Description, 'error');
    });
    return false;
  } catch (e) {
    return false;
  }
};

export const cancelContactInfoConfirmRequest = async () => {
  try {
    const { data } = await satrexApi().post('/AddressInfo/CancelConfirmRequest');
    if (data.isSuccess) {
      satrexToast('درخواست بررسی با موفقیت لغو شد', 'success');
      return true;
    }
     data.Errors.forEach((err) => {
      satrexToast(err.Description, 'error');
    });
    return false;
  } catch (e) {
    return false;
  }
};

export const redirectToBank = async (bankAccountId, price) => {
  try {
    const { data } = await satrexApi().post('/InternalPayment/Pay', {
      bankAccountId,
      price,
      gateway: '',
    });
    if (data.Errors) {
      data.Errors.forEach((err) => {
        satrexToast(err.Description, 'error');
      });
      return false;
      // satrexToast('درخواست بررسی با موفقیت لغو شد', 'success');
    }
    return data;
  } catch (e) {
    return false;
  }
};
