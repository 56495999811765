import React from 'react';
import sliderTourConfig from '../../components/slider/tourConfig';

export default [
  ...sliderTourConfig,
  {
    selector: '[data-tut="searchBarCoin"]',
    content: () => (
      <div>
        <p>برای یافتن کوین مورد نظر میتوانید از این بخش استفاده کنید</p>
      </div>
    ),
  },
  {
    selector: '[data-tut="walletCoinList"]',
    content: () => (
      <div>
        <p>لیسست تمامی کیف پول های موجود شما در این بخش قرار دارد</p>
        <p>همچنین برای مرتب کردن لیست میتوانید ردیف مورد نظر را نگه داشته و به بالا یا پایین بکشید</p>
      </div>
    ),
  },
  ];
