import React, { Suspense } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation, Switch } from 'react-router';
import { AnimatePresence } from 'framer-motion';
import AnimatedPage from '../components/AnimatedPage';
import MasterScreen from '../screens/MasterScreen';
import Loader from '../components/Loader';
import WalletDetails from '../screens/Wallet/WalletDetails';

const Home = React.lazy(() => import('../screens/Main'));
const Profile = React.lazy(() => import('../screens/Profile'));
const Wallet = React.lazy(() => import('../screens/Wallet'));
const Markets = React.lazy(() => import('../screens/Markets'));
const Market = React.lazy(() => import('../screens/Market'));
const MarketDeal = React.lazy(() => import('../screens/Market/MarketDeal'));
const Settings = React.lazy(() => import('../screens/Settings'));
const Slider = React.lazy(() => import('../components/slider'));
const About = React.lazy(() => import('../screens/About'));
const Security = React.lazy(() => import('../screens/Security'));
const ContactUs = React.lazy(() => import('../screens/ContactUs'));
const Reports = React.lazy(() => import('../screens/Reports'));
const FastDealing = React.lazy(() => import('../screens/FastDealing'));
const Support = React.lazy(() => import('../screens/Support'));
const SendTicket = React.lazy(() => import('../screens/SendTicket'));
const Accounting = React.lazy(() => import('../screens/Accounting'));
const Messages = React.lazy(() => import('../screens/Profile/screens/Messages'));
const Policies = React.lazy(() => import('../screens/Policies'));
const Guide = React.lazy(() => import('../screens/Guide'));

export default () => {
  const redirections = useSelector((state) => state.layout.redirectUrls);
  const location = useLocation();

  return (
    <>

      <MasterScreen>
        <Suspense fallback={(
          <div style={{
            width: '100%', height: 'calc(100vh - 50px)', display: 'flex', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <Loader />
          </div>
        )}
        >
          <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
              {
                redirections.map((item, index) => <Redirect key={item.toUrl} from={item.fromUrl} to={item.toUrl} />)
              }

              <Route path="/" exact>
                <Redirect to="/home" />
              </Route>
              <Route path="/setting" exact>
                <AnimatedPage>
                  <Settings />
                </AnimatedPage>
              </Route>

              <Route path="/market/:id" exact>
                <AnimatedPage>
                  <Market />
                </AnimatedPage>
              </Route>

              <Route path="/market-deal/:id" exact>
                <AnimatedPage>
                  <MarketDeal />
                </AnimatedPage>
              </Route>

              <Route path="/home" exact>
                <AnimatedPage>
                  <Home />
                </AnimatedPage>
              </Route>

              <Route path="/send-ticket" exact>
                <AnimatedPage>
                  <SendTicket />
                </AnimatedPage>
              </Route>

              <Route path="/support" exact>
                <AnimatedPage>
                  <Support />
                </AnimatedPage>
              </Route>

              <Route path="/accounting" exact>
                <AnimatedPage>
                  <Slider />
                  <Accounting />
                </AnimatedPage>
              </Route>

              <Route path="/messages" exact>
                <AnimatedPage>
                  <Slider />
                  <Messages />
                </AnimatedPage>
              </Route>

              <Route path="/fast-dealing" exact>
                <AnimatedPage>
                  <Slider />
                  <FastDealing />
                </AnimatedPage>
              </Route>

              <Route path="/about" exact>
                <AnimatedPage>
                  <About />
                </AnimatedPage>
              </Route>

              <Route path="/policies" exact>
                <AnimatedPage>
                  <Policies />
                </AnimatedPage>
              </Route>

              <Route path="/guide" exact>
                <AnimatedPage>
                  <Guide />
                </AnimatedPage>
              </Route>

              <Route path="/contact-us" exact>
                <AnimatedPage>
                  <ContactUs />
                </AnimatedPage>
              </Route>
              <Route path="/wallet/:coinname" exact>
                <AnimatedPage>
                  <WalletDetails />
                </AnimatedPage>
              </Route>
              <Route path="/wallet" exact>
                <AnimatedPage>
                  <Slider />
                  <Wallet />
                </AnimatedPage>
              </Route>

              <Route path="/security" exact>
                <AnimatedPage>
                  <Slider />
                  <Security />
                </AnimatedPage>
              </Route>

              <Route path="/markets" exact>
                <AnimatedPage>
                  <Slider />
                  <Markets />
                </AnimatedPage>
              </Route>
              <Route path="/reports" exact>
                <AnimatedPage>
                  <Slider />
                  <Reports />
                </AnimatedPage>
              </Route>

              <Route path="/profile" exact>
                <AnimatedPage>
                  <Slider />
                  <Profile />
                </AnimatedPage>
              </Route>

              {/* <Route path="/no-internet" exact> */}
              {/*  <AnimatedPage> */}
              {/*    <NoInternet /> */}
              {/*  </AnimatedPage> */}
              {/* </Route> */}
            </Switch>
          </AnimatePresence>
        </Suspense>
      </MasterScreen>

    </>
  );
};
