import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Store } from './redux/store';
import App from './App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
//
// if (module.hot && process.env.NODE_ENV !== 'production') {
//     module.hot.accept();
// }

// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'capitalize', {
    value() {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false,
});

// eslint-disable-next-line no-extend-native
Number.prototype.toFixedSpecial = function (n) {
    let str = this.toFixed(n);
    if (str.indexOf('e+') === -1) return str;

    // if number is in scientific notation, pick (b)ase and (p)ower
    str = str
        .replace('.', '')
        .split('e+')
        .reduce((b, p) => b + Array(p - b.length + 2).join(0));

    if (n > 0) str += `.${Array(n + 1).join(0)}`;

    return str;
};

ReactDOM.render(
    <Provider store={Store}>
        <App />
    </Provider>,
    document.getElementById('root'),
);

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`).then((registration) => {
            console.log('SW registered: ', registration);
        }).catch((registrationError) => {
            console.log('SW registration failed: ', registrationError);
        });
    });
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
